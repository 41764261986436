import axiosInstance from './axiosInstance';
import { ApiResponse, CustomError } from './types';

export const addHistoricalWeatherData = async (
	property_id: string,
	latitude: string,
	longitude: string,
	weather_location_id: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.post(
		`/v2/p/${property_id}/services/weather/historical-weather-data`,
		{
			latitude: latitude,
			longitude: longitude,
			property_id: property_id,
			weather_location_id: weather_location_id,
		}
	);
	return response.data;
};

export const fetchWeatherForecastData = async (
	property_id: string,
	params: {}
): Promise<ApiResponse<any[]>> => {
	const response = await axiosInstance.get(
		`/v2/p/${property_id}/weather/forecasted-weather`,
		{
			params,
		}
	);
	return response.data;
};
