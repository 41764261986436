import { Button, Dialog, DialogContent } from '@mui/material';
import '../dashboard/dataSharing/DataSharing.scss';
import styles from '../../pages/InputSheet.module.scss';
import issue from '../../assets/illustrations/issue.svg';

export default function SessionInactivityPopup({ isOpen, onLoginClick }) {
	return (
		<Dialog
			open={isOpen}
			fullWidth
			style={{ padding: '20px' }}
			PaperProps={{ sx: { maxWidth: '700px', width: 'auto' } }}
			className="DataSharingDialog">
			<DialogContent>
				<div className={styles.savePopupContainer}>
					<div className={styles.savePopupTitleContainer}>
						<img src={issue} alt="issue" />
						<div className={styles.text}>
							<h2 className={styles.title}>Session timeout!</h2>
							<p>
								You were logged out of your session following 30 minutes of
								inactivity.
							</p>
						</div>
					</div>
					<div className="DataSharingButtons">
						<Button
							variant="contained"
							onClick={onLoginClick}
							sx={{ backgroundColor: '#004225', color: '#FFF' }}>
							Login
						</Button>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
}
