/**
 * EnrollMFA shows a simple enrollment dialog. When shown on screen it calls
 * the `enroll` API. Each time a user clicks the Enable button it calls the
 * `challenge` and `verify` APIs to check if the code provided by the user is
 * valid.
 * When enrollment is successful, it calls `onEnrolled`. When the user clicks
 * Cancel the `onCancelled` callback is called.
 */

import { useEffect, useState } from 'react';
import { supabase } from '../../supabaseClient';
import '../dashboard/dataSharing/DataSharing.scss';
import fontStyles from '../../styles/Fonts.module.scss';
import styles from './auth.module.scss';
import { defaultErrorSnackbar } from '../snackbar/Snackbar';
import { LoadingButton } from '@mui/lab';

const codeLength = 6;

function MFAEnrollment({ handleEnrollmentCompletion, setShowSnackbar }) {
	const [factorId, setFactorId] = useState('');
	const [qr, setQR] = useState(''); // holds the QR code image SVG
	const [isVerifying, setIsVerifying] = useState(false);
	const [verifyCode, setVerifyCode] = useState(''); // contains the code entered by the user
	const [secret, setSecret] = useState('');

	const onSubmitClicked = () => {
		(async () => {
			setIsVerifying(true);
			try {
				// Gets the challenge code for the MFA factor
				const challenge = await supabase.auth.mfa.challenge({ factorId });
				if (challenge.error) {
					throw challenge.error;
				}

				const challengeId = challenge.data.id;

				// Verify the code against the user input
				const verify = await supabase.auth.mfa.verify({
					factorId,
					challengeId,
					code: verifyCode,
				});
				if (verify.error) {
					throw verify.error;
				}
				handleEnrollmentCompletion();
			} catch (error) {
				console.error('Error during MFA process:', error);
				setShowSnackbar({
					...defaultErrorSnackbar,
					msg: 'Unable to verify the code. Please try again.',
				});
			} finally {
				setIsVerifying(false);
			}
		})();
	};

	useEffect(() => {
		(async () => {
			const { data, error } = await supabase.auth.mfa.enroll({
				factorType: 'totp',
			});
			if (error) {
				throw error;
			}
			setSecret(data.totp.secret);
			setFactorId(data.id);

			// Supabase Auth returns an SVG QR code which you can convert into a data
			// URL that you can place in an <img> tag.
			setQR(data.totp.qr_code);
		})();
	}, []);

	return (
		<div className={styles.mfaEnrollmentContainer}>
			<p className={styles.title}>
				<span className={fontStyles.keyTitle}>Multi-factor authentication</span>
				<br />
				<span className={fontStyles.cardDescription}>
					Scan the QR code on your authenticator app.
				</span>
			</p>
			<div className={styles.codesToAdd}>
				<img
					className={styles.qrCode}
					src={qr}
					alt="qr-code-multi-factor-authentication"
				/>

				<p
					className={`${fontStyles.cardInstructions} ${styles.spacedOutTitles}`}>
					<span>Or enter this code in your authenticator app:</span>
					<span
						className={`${fontStyles.cardDescription} ${fontStyles.goodText}`}>
						{secret}
					</span>
				</p>
			</div>

			<p className={styles.verificationCode}>
				<span className={fontStyles.cardSmallTitle}>
					Enter the verification code here:
				</span>
				<br />
				<span className={fontStyles.cardDescription}>
					Find this code on your authenticator app under Joulea.
				</span>
				{/* Allow only digits and set a max length limit of 6. Submit button gets enabled at 6 */}
			</p>
			<VerificationCodeInputAndSubmit
				{...{ verifyCode, setVerifyCode, isVerifying, onSubmitClicked }}
			/>
		</div>
	);
}

export default MFAEnrollment;

export function VerificationCodeInputAndSubmit({
	verifyCode,
	setVerifyCode,
	isVerifying,
	onSubmitClicked,
}) {
	return (
		<>
			<input
				value={verifyCode}
				type="number"
				onInput={(e) => (e.target.value = e.target.value.slice(0, codeLength))}
				onChange={(e) => setVerifyCode(e.target.value)}
				className={styles.verificationInput}
				placeholder="Enter the 6-digit code"
			/>
			<LoadingButton
				onClick={onSubmitClicked}
				fullWidth
				loading={isVerifying}
				variant="contained"
				disabled={!verifyCode || String(verifyCode).length !== codeLength}
				className={styles.submitButton}>
				Verify
			</LoadingButton>
		</>
	);
}
