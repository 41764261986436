import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { userApi } from '../../api/';

// TODO: add rest of fields for account db
interface UserState {
	userDetails: {
		role: number | null;
		email: string;
		group_name: string;
		group_id: string;
		first_name: string;
		last_name: string;
		user_id: string;
		// TODO: add unit types enum
		units: {
			heating: string;
			cooling: string;
			lighting: string;
			fans: string;
			pumps: string;
			plugLoad: string;
			dhw: string;
			electricVehicle: string;
			pv: string;
			wind: string;
			total: string;
		};
	};
	loading: boolean;
	error: string;
}

const initialState: UserState = {
	userDetails: {
		role: null,
		email: '',
		group_name: '',
		group_id: '',
		first_name: '',
		last_name: '',
		user_id: '',
		units: {
			heating: 'kWh',
			cooling: 'kWh',
			lighting: 'kWh',
			fans: 'kWh',
			pumps: 'kWh',
			plugLoad: 'kWh',
			dhw: 'kWh',
			electricVehicle: 'kWh',
			pv: 'kWh',
			wind: 'kWh',
			total: 'kWh',
		},
	},
	loading: false,
	error: '',
};

export const getUserDetails = createAsyncThunk(
	'user/getUserDetails',
	async (payload: any, thunkAPI) => {
		try {
			const { data: user } = await userApi.getUserDetail();
			return user;
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error);
		}
	}
);

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		updateUserDetails: (state, { payload }) => {
			state.userDetails = payload;
		},
		resetUser: (_) => {
			return initialState;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getUserDetails.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(getUserDetails.fulfilled, (state, action) => {
			state.loading = false;
			state.userDetails = action.payload;
			state.error = '';
		});
		builder.addCase(getUserDetails.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload as string;
		});
	},
});

export const { updateUserDetails, resetUser } = userSlice.actions;

export default userSlice.reducer;
