import { Button } from '@mui/material';
import fontStyles from '../../../../../styles/Fonts.module.scss';

export function ChargingStationsSelectionPopup({
	onBack,
	chargingLocations,
	additionalNotes,
	setDroneLogisticsData,
	handlePopupClose,
	setWasSubmitted,
}) {
	const handleChargingLocationsChange = (e) => {
		setDroneLogisticsData((prevData) => ({
			...prevData,
			chargingLocations: e.target.value,
		}));
	};

	const handleAdditionalNotesChange = (e) => {
		setDroneLogisticsData((prevData) => ({
			...prevData,
			additionalNotes: e.target.value,
		}));
	};

	return (
		<div>
			<p className={fontStyles.cardSmallTitle} style={{ marginBottom: '2px' }}>
				What are suitable locations for charging the drones?{' '}
				<span style={{ color: 'red' }}>*</span>
			</p>
			<p
				className={fontStyles.cardInstructions}
				style={{ marginBottom: '12px' }}>
				Please suggest indoor locations such as the lobby area, the dining area,
				etc. to charge the drone batteries for optimal performance for flight
				time and charging.
			</p>
			<textarea
				id="preferred_locations"
				rows="5"
				placeholder="Describe your preferred locations here."
				onChange={handleChargingLocationsChange}
				value={chargingLocations}></textarea>
			<br />
			<p className={fontStyles.cardSmallTitle}>Additional notes.</p>
			<textarea
				id="preferred_locations"
				rows="5"
				placeholder="Anything we should keep in mind?"
				onChange={handleAdditionalNotesChange}
				value={additionalNotes}></textarea>
			<div className="DataSharingButtons">
				<Button onClick={onBack} className="backButton">
					Back
				</Button>
				<Button
					onClick={() => {
						setWasSubmitted(true);
						handlePopupClose();
					}}
					variant="contained"
					disabled={!chargingLocations}>
					SUBMIT
				</Button>
			</div>
		</div>
	);
}
