import dayjs from 'dayjs';
import fontStyles from '../../../../../styles/Fonts.module.scss';
const dateDisplayFormat = 'DD MMM, ddd';

export function SelectionInstructions({ selectedDates, jouleaPreferredDates }) {
	return (
		<div>
			<p>Please select your preferred 3-week window for the drone flights. </p>
			<p className={`${fontStyles.cardSmallTitle} newSectionTitle`}>
				Preferred days based on the weather forecast:
			</p>
			{jouleaPreferredDates && jouleaPreferredDates?.length > 0 ? (
				<ul>
					{jouleaPreferredDates.map((date) => (
						<li key={date}>{dayjs(date).format(dateDisplayFormat)}</li>
					))}
				</ul>
			) : (
				<p className={fontStyles.cardInstructions}>No preferred days found.</p>
			)}
			<p className={`${fontStyles.cardSmallTitle} newSectionTitle`}>
				Selected days:
			</p>
			{selectedDates && selectedDates.length > 0 ? (
				<p>
					{dayjs(selectedDates[0]).format(dateDisplayFormat)} until{' '}
					{dayjs(selectedDates.at(-1)).format(dateDisplayFormat)}
				</p>
			) : (
				<p className={fontStyles.cardInstructions}>
					No days selected. Please select on the calendar.
				</p>
			)}
		</div>
	);
}
