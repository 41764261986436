import { useSelector } from 'react-redux';
import {
	buildingDocumentsFilenamePrefix,
	buildingDocumentsDescriptions,
	buildingDocumentsDisplayName,
	buildingDocumentsJsonKey,
	requiredBuildingDocuments,
	documentKeys,
} from '../../const';
import FileUpload from './FileUpload';
import { LoadingButton } from '@mui/lab';
import buttonStyles from '../../../../uiTemplates/Button.module.scss';

const waterAcceptedFileTypes = ['pdf', 'xls', 'xlsx', 'csv'];

function BuildingDocumentsOnboarding({ onClose, setShowSnackbar }) {
	const selectedBuilding = useSelector((state) => state.selectedBuilding);
	const inputData = selectedBuilding.input_sheet;
	// Check if all required documents are uploaded
	const isAllRequiredDocsUploaded = requiredBuildingDocuments.every(
		(doc) => inputData[doc]?.length > 0
	);

	return (
		<>
			<div className="FileUploadContainer">
				{
					// Loop through all the documents and display the FileUpload component for each
					documentKeys &&
						typeof documentKeys === 'object' &&
						Object.values(documentKeys).map((docKey) => (
							<FileUpload
								key={docKey}
								documentName={buildingDocumentsDisplayName?.[docKey]}
								description={buildingDocumentsDescriptions?.[docKey]}
								inputData={inputData}
								inputJsonKey={buildingDocumentsJsonKey?.[docKey]}
								propertyId={selectedBuilding.prop_id}
								filenamePrefix={buildingDocumentsFilenamePrefix?.[docKey]}
								setShowSnackbar={setShowSnackbar}
								{...(docKey === documentKeys.WATER_BILL && {
									allowedFileTypes: waterAcceptedFileTypes,
								})}
							/>
						))
				}
			</div>
			<div className={buttonStyles.dataSharingButtons}>
				<LoadingButton
					disabled={!isAllRequiredDocsUploaded}
					variant="contained"
					onClick={onClose}>
					Submit
				</LoadingButton>
			</div>
		</>
	);
}

export default BuildingDocumentsOnboarding;
