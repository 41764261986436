import { React, useState } from 'react';
import './NumberInputStyle.scss';

// Simple component to be used for inputting numbers throughout the input sheet.
// propValue is the value to display in the input box, getChange is the callback function to call on value change,
// name is just a label to give to the html input (not super important), id is an id to give to html input (not important),
// placeholder is the text to display when the input value is empty (''),
// classes is a string holding whatever styling classes the user wants to apply to the input box,
// and disabled is whether the input box should be disabled (true) or enabled (false)
export default function NumberInput({
	propValue,
	getChange,
	name,
	id,
	placeholder,
	classes,
	disabled = false,
	maxValue = false,
	invalid = false,
	unit = '',
}) {
	const [errorMsg, setErrorMsg] = useState('');
	return (
		// The div used to be necessary when we had the +/- buttons, but now it isn't.
		// However, it works as is, so there's no real point in changing it
		<div className="numberfieldContainer">
			{/* styled html input */}
			<input
				type="number"
				name={name}
				id={id}
				placeholder={placeholder}
				className={`${classes} numberField ${
					(errorMsg || invalid) && 'errorOutline'
				}`}
				max={maxValue ? maxValue : undefined}
				// The onWheel handler disables the default behavior of changing the number value if you scroll your mouse within input box
				value={propValue ?? ''}
				onWheel={(e) => e.target.blur()}
				onChange={(e) => {
					// Set an error message if the value exceeds the maximum
					if (maxValue && e.target.value > maxValue)
						setErrorMsg(`Please input a maximum value of ${maxValue}.`);
					else setErrorMsg('');

					// If the input can't be parsed to a number, then we should just call the callback function with an empty string.
					// If it can be parsed into a number, parse it and pass that number into the getChange function
					getChange(
						isNaN(parseFloat(e.target.value)) ? '' : parseFloat(e.target.value)
					);
				}}
				disabled={disabled}
			/>
			{unit && <span className="unit">{unit}</span>}
			{/* Show the error message if any set */}
			{errorMsg && <p className="errorMessage">{errorMsg}</p>}
			{/* We have removed the +/- buttons on the side of the input box, but I'm saving this as comment in case we decide to add it back in 
            <span className="numberIndicators">
                <span onClick={() => getChange(!disabled ? (propValue ? propValue+1 : 1) : '')}>+</span>
                <span onClick={() => getChange(propValue ? propValue-1 : '')}>-</span>
            </span> */}
		</div>
	);
}
