export class CustomError extends Error {
	code: string | undefined;
	status: number;
	constructor(message: string, code: string | undefined, status: number) {
		super(message);
		this.name = this.constructor.name;
		this.code = code;
		this.status = status;
	}
}

// Define the type for the error object in the API response
export interface ErrorResponse {
	code: string;
	message: string;
}

// Define the complete API response type
export type ApiResponse<T = any> = {
	status: 'success' | 'error';
	data: T | T[];
	message?: string;
	error?: ErrorResponse;
	meta?: any; // Replace 'any' with the appropriate type for metadata if available
};
