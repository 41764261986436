import { styled } from '@mui/material/styles';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import dayjs from 'dayjs';
import { maxSelectableDays, totalSelectionDays } from './DroneLogistics';
import globalVariables from '../../../../../styles/_global.scss';

const brightGreen = '#33C8A6';
const lightGreen = '#D3EAE4';

const selectedIconStyle = {
	backgroundColor: globalVariables.neutral2,
	color: brightGreen,
	border: `1px solid ${brightGreen}`,
};

const HighlightedDay = styled(PickersDay)(({ _ }) => ({
	'&.Mui-selected': selectedIconStyle,
	'&.Mui-selected: hover': {
		...selectedIconStyle,
		fontWeight: 'bold',
	},
	'&.Mui-selected:focus': selectedIconStyle,
	'&.Mui-disabled:not(.Mui-selected)': {
		color: globalVariables.neutral4,
	},
}));

//Higlights the dates in highlightedDays array
const HighlightDays = (props) => {
	const {
		selectedDates = [],
		jouleaPreferredDates = [],
		day,
		outsideCurrentMonth,
		...other
	} = props;

	const isSelected =
		!props.outsideCurrentMonth &&
		selectedDates.includes(day.format('YYYY-MM-DD'));

	const isPreferred = jouleaPreferredDates.includes(day.format('YYYY-MM-DD'));
	const dateStyle = isSelected
		? {
				backgroundColor: `${globalVariables.primary} !important`,
				color: globalVariables.neutral2,
		  }
		: isPreferred
		? { backgroundColor: lightGreen, color: globalVariables.black }
		: {
				backgroundColor: globalVariables.neutral2,
				color: globalVariables.black,
		  };

	return (
		<HighlightedDay
			{...other}
			outsideCurrentMonth={outsideCurrentMonth}
			day={day}
			sx={dateStyle}
		/>
	);
};

export function CalendarDateSelector({
	today,
	selectedDates,
	jouleaPreferredDates,
	handleDateChange,
}) {
	const maxDate = today.add(maxSelectableDays, 'day');

	// Disable the last 21 days so that the selection stays within the 3-month limit
	// Is custom disabled instead of reducing it from maxDate since the months after maxDate are not visible
	// and the user will not be able to see until the end of the selection window
	const disabledDays = (date) => {
		const dayOfWeek = dayjs(date).get('day');
		const diffWithMaxDate = maxDate.diff(date, 'day');
		return (
			dayOfWeek === 0 || dayOfWeek === 6 || diffWithMaxDate < totalSelectionDays
		);
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<StaticDatePicker
				displayStaticWrapperAs="desktop"
				defaultValue={today}
				shouldDisableDate={disabledDays}
				minDate={today}
				maxDate={maxDate}
				slots={{
					day: HighlightDays,
				}}
				slotProps={{
					day: { selectedDates, jouleaPreferredDates },
				}}
				onChange={handleDateChange}
			/>
		</LocalizationProvider>
	);
}
