import axiosInstance from './axiosInstance';
import { AxiosRequestConfig } from 'axios';
import { ApiResponse, CustomError } from './types';
import { propertyApi } from './';
import { userRoleMap } from '../utils/maps';

type UserFilter = {
	email: string;
	invited_by?: string;
	group_id?: string;
	invitation_id?: string;
};

type UserUpdatePayload = {
	first_name: string;
	last_name: string;
	status: boolean;
	user_id: string;
	group_id: string;
};

export type PropUserFilter = {
	prop_id?: string;
	user_id?: string;
	group_id?: string;
	role?: number;
};

export type UserCreationPayload = {
	email: string;
	role: number;
	group_id: string;
	invited_by: string;
};

export const getUserDetail = async (): Promise<ApiResponse<any>> => {
	try {
		const response = await axiosInstance.get(`/v2/users/me`);

		return response.data;
	} catch (error) {
		throw error;
	}
};

export const checkUserEmailAvailability = async (
	email: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.get('/v2/users/availability', {
		params: { email },
	});

	return response.data;
};

export const createUser = async (
	data: UserCreationPayload
): Promise<ApiResponse<any>> => {
	try {
		const response = await axiosInstance.post('/v1/users', data);

		return response.data;
	} catch (error) {
		throw error;
	}
};

export const updateMember = async (
	userData: UserUpdatePayload,
	role: number
): Promise<ApiResponse<any>> => {
	try {
		const response = await axiosInstance.put(
			`/v2/g/${userData.group_id}/users/update-member-access`,
			{
				user_data: userData,
				role: role,
			}
		);

		return response.data;
	} catch (error) {
		throw error;
	}
};

export const updateUser = async (
	userId: string,
	data: {}
): Promise<ApiResponse<any>> => {
	try {
		const response = await axiosInstance.put(`/v1/users/${userId}`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const updateCurrentUser = async (
	userData: UserUpdatePayload
): Promise<ApiResponse<any>> => {
	try {
		const response = await axiosInstance.put(`/v2/users/me`, userData);

		return response.data;
	} catch (error) {
		throw error;
	}
};

// TODO: [SET-1742] Needs to be rewritten (check if it is still needed)
export const getPropUsers = async (
	filter: PropUserFilter
): Promise<ApiResponse<any[]>> => {
	try {
		const response = await axiosInstance.get(`/v1/prop_users`, {
			params: filter,
		});

		return response.data;
	} catch (error) {
		throw error;
	}
};

export const fetchUsersWithProperties = async (userFilter: UserFilter) => {
	try {
		const { data: userData } = await getUserDetail();

		if (userData.length === 0) {
			return { data: [], error: null };
		}
		const usersWithProperties = await Promise.all(
			userData
				.filter((user: any) => user.role !== userRoleMap.Admin)
				.map(async (user: any) => {
					let properties = [];
					try {
						const propertyListData = await propertyApi.getPropertyList(
							user.group_id
						);
						properties = propertyListData.data;
					} catch (error) {}

					return { ...user, property: properties };
				})
		);

		// Ensure at least one user object with an empty array for properties
		if (usersWithProperties.length === 0) {
			const emptyUser = { ...userData[0], property: [] };
			usersWithProperties.push(emptyUser);
		}

		return { data: usersWithProperties, error: null };
	} catch (error) {
		throw error;
	}
};

export const fetchTeamMembersWithProperties = async (group_id: string) => {
	try {
		const response = await axiosInstance.get(
			`/v2/g/${group_id}/users/team-members-properties`
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const fetchUserDataWithPropertiesAndUsers = async (
	userFilter: UserFilter,
	currentUser: any
) => {
	try {
		const usersWithPropertiesResponse = await fetchUsersWithProperties(
			userFilter
		);
		const { data: usersWithProperties } = usersWithPropertiesResponse;

		if (usersWithProperties.length === 0) {
			return { data: [], error: null };
		}

		const finalData = await Promise.all(
			usersWithProperties.map(async (user: any) => {
				const propUserFilter: PropUserFilter = {
					user_id: user.user_id,
				};
				let propUsers = [];
				// TODO [SET-1742] Needs to be rewritten (check if it is still needed)
				try {
					const propUsersData = await getPropUsers(propUserFilter);
					propUsers = propUsersData.data;
				} catch (error) {}

				return { ...user, prop_users: propUsers };
			})
		);

		return { data: finalData, error: null };
	} catch (error) {
		throw error;
	}
};

export const updatePropertiesOfTeamMember = async (
	group_id: string,
	user_id: string,
	propertyIds: [],
	readOnly: boolean
): Promise<ApiResponse<any>> => {
	try {
		const response = await axiosInstance.put(
			`/v2/g/${group_id}/users/properties`,
			{ user_id: user_id, property_ids: propertyIds, read_only: readOnly }
		);

		return response.data;
	} catch (error) {
		throw error;
	}
};

export const addPropertiesOfTeamMember = async (
	group_id: string,
	user_id: string,
	propertyIds: [],
	readOnly: boolean
): Promise<ApiResponse<any>> => {
	try {
		const response = await axiosInstance.post(
			`/v2/g/${group_id}/users/properties`,
			{ user_id: user_id, property_ids: propertyIds, read_only: readOnly }
		);

		return response.data;
	} catch (error) {
		throw error;
	}
};

export const updatePropertiesOfUser = async (
	userId: string,
	data: {}
): Promise<ApiResponse<any>> => {
	try {
		const response = await axiosInstance.put(
			`/v1/users/${userId}/properties`,
			data
		);

		return response.data;
	} catch (error) {
		throw error;
	}
};

export const deletePropertiesOfUser = async (
	userId: string
): Promise<ApiResponse<any>> => {
	try {
		const response = await axiosInstance.delete(
			`/v1/users/${userId}/properties`
		);

		if (response.status !== 204) {
			throw new CustomError(
				"User's properties could not be deleted",
				'DELETE_ERROR',
				response.status
			);
		}

		return response.data;
	} catch (error) {
		throw error;
	}
};

export const deleteCurrentUser = async (): Promise<ApiResponse<any>> => {
	try {
		const response = await axiosInstance.delete(`/v2/users/me`);

		if (response.status !== 204) {
			throw new CustomError(
				'Current User could not be Deleted',
				'DELETE_ERROR',
				response.status
			);
		}

		return response.data;
	} catch (error) {
		throw error;
	}
};

export const deletetUser = async (
	userId: string
): Promise<ApiResponse<any>> => {
	try {
		const response = await axiosInstance.delete(`/v1/users/${userId}`);

		if (response.status !== 204) {
			throw new CustomError(
				'User could bd not Deleted',
				'DELETE_ERROR',
				response.status
			);
		}

		return response.data;
	} catch (error) {
		throw error;
	}
};
