import axiosInstance from './axiosInstance';

export const sendEmail = async (toEmail, subject, content) => {
	const params = {
		from_email: 'no-reply@joulea.com',
		to_email: toEmail,
		subject: subject,
		body: content,
	};

	const response = await axiosInstance.post(
		`send-email`,
		JSON.stringify(params)
	);

	return response;
};

export const sendEmailInviteOwner = async (data) => {
	try {
		const response = await axiosInstance.post(
			`/email/send-email-invite-owner`,
			data
		);

		return response.data;
	} catch (error) {
		throw error;
	}
};

export const sendEmailAddBuilding = async (data) => {
	try {
		const response = await axiosInstance.post(
			`/email/send-email-add-building`,
			data
		);

		return response.data;
	} catch (error) {
		throw error;
	}
};

export const sendEmailInviteTeam = async (data) => {
	try {
		const response = await axiosInstance.post(
			`/email/send-email-invite-team`,
			data
		);

		return response.data;
	} catch (error) {
		throw error;
	}
};
