import axios, {
	InternalAxiosRequestConfig,
	AxiosResponse,
	AxiosError,
} from 'axios';
import { store } from '../Store';
import { ApiResponse, CustomError } from './types';

// Create an instance of Axios with common headers
const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_backend_api_url,
	// Add your common headers here
	headers: {
		'Content-Type': 'application/json',
		// Add any other headers you want to include in all requests
	},
});

export const axiosFormPost = (
	url: string,
	formData: FormData,
	setFileProgress: (fileprogress: number) => void
) => {
	return axiosInstance.post(url, formData, {
		headers: addSecureHeaders({
			'Content-Type': 'multipart/form-data',
		}),
		onUploadProgress: (progressEvent) => {
			const { loaded, total } = progressEvent;
			if (total) {
				const percentCompleted = Math.round((loaded * 100) / total);
				setFileProgress(percentCompleted);
			}
		},
	});
};

export const addSecureHeaders = (headers: any = {}) => {
	const accessToken = headers?.token || store?.getState()?.auth?.accessToken;

	if (accessToken) {
		// Use type assertion to let TypeScript know that headers is not undefined
		(headers as any).Authorization = `Bearer ${accessToken}`;
	}
	return headers;
};

// Add an Axios interceptor to set the Authorization header with the access token
axiosInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
	addSecureHeaders(config.headers);
	return config;
});

// Add an Axios interceptor to handle errors globally
axiosInstance.interceptors.response.use(
	(response: AxiosResponse<ApiResponse>) => response,
	(error: AxiosError<ApiResponse>) => {
		if (error.response) {
			// Extract the error details from the response
			const { status, data } = error.response;

			// Check if data exists before accessing its properties
			const errorMessage =
				data && data.error && data.error.message
					? data.error.message
					: error.message;
			const errorCode =
				data && data.error && data.error.code ? data.error.code : error.code;

			// Throw the custom error with status to be handled by the caller of the API function
			throw new CustomError(errorMessage, errorCode, status);
		} else {
			// For network errors, handle them here
			console.error('Network Error:', error.message);

			// Throw the custom error with status to be handled by the caller of the API function
			throw new CustomError('Network error occurred.', 'NETWORK_ERROR', 500);
		}
	}
);

export default axiosInstance;
