import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import toggleButtonStyles from '../../../../inputSheet/ToggleButton.module.scss';
import { inputJsonKeys, energyDirectPageKeys } from './EnergyDirectOnboarding';

export function AccountExists({ inputData, updateEnergyDirectData }) {
	const handleToggleChange = (_, value) => {
		updateEnergyDirectData({
			[inputJsonKeys.energyDirectAccountExists]: value,
			[inputJsonKeys.energyDirectPageNumber]:
				value === true
					? energyDirectPageKeys.UPLOAD_UTILITY_BILL
					: value === false
					? energyDirectPageKeys.UPLOAD_CONSUMPTION_DATA
					: energyDirectPageKeys.ACCOUNT_EXISTS,
		});
	};

	return (
		<div className="InputContainer">
			<label htmlFor={'hasEnergyDirectAccount'}>
				<span className="labelTitle">
					Do you have an Energy Direct account?
				</span>
				Joulea will request access to your Energy Direct accounts as a secondary
				user to directly collect demand and cost data.
			</label>
			<ToggleButtonGroup
				className={toggleButtonStyles.popupButtonGroupContainer}
				value={inputData[inputJsonKeys.energyDirectAccountExists]}
				exclusive
				onChange={handleToggleChange}>
				{Object.entries({
					Yes: true,
					No: false,
				}).map(([label, value]) => (
					<ToggleButton
						key={label}
						value={value}
						className={toggleButtonStyles.popupButton}>
						{label}
					</ToggleButton>
				))}
			</ToggleButtonGroup>
		</div>
	);
}
