import axiosInstance from './axiosInstance';
import { ApiResponse } from './types';

export const getDroneLogisticsData = async (
	propertyId: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.get(
		`/v2/p/${propertyId}/ee/drone-logistics`
	);
	return response.data;
};

export const updateDroneLogisticsData = async (
	propertyId: string,
	drone_logistics_data: any
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.post(
		`/v2/p/${propertyId}/ee/drone-logistics`,
		{
			drone_logistics_data,
		}
	);
	return response.data;
};
