import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as weatherApi from '../../api/weatherApi';

export const getWeatherForecast = createAsyncThunk(
	'weather/getWeatherForecast',
	async (payload, thunkAPI) => {
		try {
			const { data: weatherData } = await weatherApi.fetchWeatherForecastData(
				payload.propertyId,
				{
					'start-time': payload.startTime,
					'end-time': payload.endTime,
				}
			);
			if (weatherData?.length) {
				return { weatherData };
			} else {
				return thunkAPI.rejectWithValue('No data found');
			}
		} catch (error) {
			return thunkAPI.rejectWithValue('Error fetching data');
		}
	}
);

const weatherSlice = createSlice({
	name: 'weather',
	initialState: {
		weatherData: null,
		weatherDataError: null,
		weatherDataLoading: false,
	},
	extraReducers: (builder) => {
		builder.addCase(getWeatherForecast.pending, (state) => {
			state.weatherDataLoading = true;
			state.weatherDataError = null;
		});
		builder.addCase(getWeatherForecast.fulfilled, (state, { payload }) => {
			state.weatherData = payload.weatherData;
			state.weatherDataLoading = false;
			state.weatherDataError = null;
		});
		builder.addCase(getWeatherForecast.rejected, (state, { error }) => {
			state.weatherDataError = error.message;
			state.weatherDataLoading = false;
			state.weatherData = null;
		});
	},
});

export default weatherSlice.reducer;
