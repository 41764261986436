import { useState } from 'react';
import dayjs from 'dayjs';
import { Button, Stack } from '@mui/material';
import { CalendarDateSelector } from './CalendarDateSelector';
import { SelectionInstructions } from './SelectionInstructions';
import { totalSelectionDays } from './DroneLogistics';

export function DateSelectionPopup({
	onSubmit,
	selectedDates,
	setDroneLogisticsData,
}) {
	//TODO [SET-2130]: Get Joulea team's preferred list of dates from the backend
	const [jouleaPreferredDates, setJouleaPreferredDates] = useState([]);

	const handleDateChange = (newValue) => {
		const newHighlightedDays = [];
		for (let i = 0; i < totalSelectionDays; i++) {
			const newDate = newValue.add(i, 'day');
			if (newDate.get('day') !== 0 && newDate.get('day') !== 6) {
				newHighlightedDays.push(newDate.format('YYYY-MM-DD'));
			}
		}
		setDroneLogisticsData((prevData) => ({
			...prevData,
			selectedDates: newHighlightedDays,
		}));
	};

	const handleScheduleSubmit = () => {
		onSubmit();
	};

	const today = dayjs();

	return (
		<>
			<Stack direction="row" spacing={2}>
				<SelectionInstructions {...{ selectedDates, jouleaPreferredDates }} />
				<CalendarDateSelector
					{...{
						today,
						selectedDates,
						jouleaPreferredDates,
						handleDateChange,
					}}
				/>
			</Stack>
			<div className="DataSharingButtons">
				<Button
					onClick={handleScheduleSubmit}
					variant="contained"
					disabled={!(selectedDates && selectedDates.length > 0)}>
					NEXT
				</Button>
			</div>
		</>
	);
}
