import { useState } from 'react';
import {
	CongratsTemplate,
	SimpleActionButtonGroup,
} from '../../CommonTemplates';
import fontStyles from '../../../../../styles/Fonts.module.scss';
import '../../DataSharing.scss';

const guidedSessionOverviewKeys = {
	INTRO: 0,
	HVAC: 1,
	MATERIALS: 2,
	NEXT_STEPS: 3,
};

const GuidedSessionOverview = ({ onClose }) => {
	const [currentPage, setCurrentPage] = useState(
		guidedSessionOverviewKeys.INTRO
	);
	const guidedSessionPages = {
		[guidedSessionOverviewKeys.INTRO]: <Intro {...{ setCurrentPage }} />,
		[guidedSessionOverviewKeys.HVAC]: <Hvac {...{ setCurrentPage }} />,
		[guidedSessionOverviewKeys.MATERIALS]: (
			<Materials {...{ setCurrentPage }} />
		),
		[guidedSessionOverviewKeys.NEXT_STEPS]: (
			<NextSteps {...{ onClose, setCurrentPage }} />
		),
	};
	return <div>{guidedSessionPages[currentPage]}</div>;
};

export default GuidedSessionOverview;

function Intro({ setCurrentPage }) {
	return (
		<div>
			<div className="guidedSessionInstructionsTitle">
				<span className="step-number">1</span>
				<p>
					<span className={`${fontStyles.cardSmallTitle} title`}>
						Please review the information to be collected during the guided
						session.
					</span>
					<br />
					<span>
						Before our second guided onboarding session, please take a moment to
						review the questions in the next section. Having this information
						ready will ensure a smoother onboarding process.
					</span>
				</p>
			</div>
			<SimpleActionButtonGroup
				setCurrentPage={setCurrentPage}
				showBack={false}
			/>
		</div>
	);
}

function Hvac({ setCurrentPage }) {
	return (
		<div>
			<div className="guidedSessionInstructionsTitle">
				<span className="step-number">2</span>
				<p>
					<span className={`${fontStyles.cardSmallTitle} title`}>
						HVAC details including the model, capacity, efficiency, fan
						horsepower, etc. for the applicable equipments:
					</span>
					<br />
					<ul>
						<li>
							Heating equipment (Boiler, Electric resistance, District heating
							connection)
						</li>
						<li>
							Cooling equipment (Water/air cooled chiller, DX - Direct
							expansion, District cooling connection)
						</li>
						<li>Cooling tower</li>
						<li>Air/water source heat pump</li>
						<li>AHU, Unitary systems, Hydronic, Zone terminal units, SWUD</li>
						<li>
							Pumps (details collected include the Gallons Per Minute (GPM),
							Head, Efficiency (EFF), Horsepower (HP), Brake Horsepower (BHP),
							and Variable Frequency Drive (VFD) of pumps)
						</li>
					</ul>
				</p>
			</div>
			<SimpleActionButtonGroup setCurrentPage={setCurrentPage} />
		</div>
	);
}

function Materials({ setCurrentPage }) {
	return (
		<div>
			<div className="guidedSessionInstructionsTitle">
				<span className="step-number">3</span>
				<p>
					<span className={`${fontStyles.cardSmallTitle} title`}>
						Building materials and floors
					</span>
					<br />
					<span className={`${fontStyles.cardInstructions} .subTitles`}>
						Floor information:
					</span>
					<ul>
						<li>Floor count, typical slab and floor height</li>
						<li>Floor names</li>
					</ul>
					<br />
					<span className={`${fontStyles.cardInstructions} .subTitles`}>
						Materials present in the walls and roof:
					</span>
					<ul>
						<li>The R-values of the roof and walls</li>
						<li>The material composition of the roof and walls</li>
						<li>
							The U-value, emissivity, SHGC (Solar Heat Gain Coefficient), etc.
							of the windows
						</li>
					</ul>
					<br />
					<span className={`${fontStyles.cardInstructions} .subTitles`}>
						Atypical set points:
					</span>
					<ul>
						<li>Areas with different setpoint or ventilation requirements</li>
						<li>Areas with different occupancy schedules</li>
					</ul>
				</p>
			</div>
			<SimpleActionButtonGroup setCurrentPage={setCurrentPage} />
		</div>
	);
}

function NextSteps({ onClose, setCurrentPage }) {
	return (
		<CongratsTemplate
			title="That was it! You are now ready to fill the form."
			message="The 'Guided session: HVAC and more' form is available in the next section. Feel free to start entering your information right away or browse the questions to ensure you're fully prepared for the guided session."
			onClick={onClose}
			onBackClick={() => setCurrentPage((currentPage) => currentPage - 1)}
			additionalTitleStyles={{ width: '100%' }}
		/>
	);
}
