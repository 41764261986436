import axiosInstance from './axiosInstance';
import { ApiResponse } from './types';

// Fetch just the accuracy values for the home page
export const fetchC2bemAccuracy = async (
	property_id: string
): Promise<ApiResponse<any[]>> => {
	const response = await axiosInstance.get(
		`/v2/p/${property_id}/c2bem/get-model-accuracy`
	);
	return response.data;
};

export const fetchC2bemConsumptionData = async (
	property_id: string
): Promise<ApiResponse<any[]>> => {
	const response = await axiosInstance.get(
		`/v2/p/${property_id}/c2bem/get-consumption-data`
	);
	return response.data;
};

export const fetchC2bemDistributionData = async (
	property_id: string
): Promise<ApiResponse<any[]>> => {
	const response = await axiosInstance.get(
		`/v2/p/${property_id}/c2bem/get-distribution-data`
	);
	return response.data;
};

export const fetchC2bemBenchmarkData = async (): Promise<
	ApiResponse<any[]>
> => {
	const response = await axiosInstance.get('/benchmark/get-benchmark-data');
	return response.data;
};
