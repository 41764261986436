import { useSelector } from 'react-redux';
import EnergyStarConnectionSteps from './EnergyStarConnectionSteps';
import EnergyStarSharePropertySteps from './EnergyStarSharePropertySteps';
import { updateInputSheetWithObj } from '../../../../../redux/selectedBuilding/SelectedBuildingSlice';
import {
	AlreadyConnected,
	AlreadyShared,
	SuccessPopup,
} from './EnergyStarSuccess';
import { GetAccountNumber, GetPropertyNumber } from './GetEnergyStarInputs';
import { useDispatch } from 'react-redux';
import { initialInputSheet } from '../../../../../assets/initial_input_sheet';

export const inputJsonKeys = {
	energyStarAccountNumber: 'energyStarAccountNumber',
	energyStarPropertyNumber: 'energyStarPropertyNumber',
	isEnergyStarConnected: 'isEnergyStarConnected',
	isEnergyStarShared: 'isEnergyStarShared',
	energyStarPageNumber: 'energyStarPageNumber',
};

export const energyStarPageKeys = {
	GET_ACCOUNT_NUMBER: 0,
	ALREADY_CONNECTED: 1,
	CONNECT_ACCOUNT: 2,
	GET_PROPERTY_NUMBER: 3,
	ALREADY_SHARED: 4,
	SHARE_PROPERTY: 5,
	SUCCESS: 6,
};

function EnergyStarOnboarding({ onClose, setShowSnackbar }) {
	const dispatch = useDispatch();
	const selectedBuilding = useSelector((state) => state.selectedBuilding);
	const propId = selectedBuilding?.prop_id;
	const inputSheetData = selectedBuilding?.input_sheet ?? initialInputSheet;

	// Energy Star access status
	const accountNumber = inputSheetData?.[inputJsonKeys.energyStarAccountNumber];
	const propertyNumber =
		inputSheetData?.[inputJsonKeys.energyStarPropertyNumber];
	const isConnected = inputSheetData?.[inputJsonKeys.isEnergyStarConnected];
	const isShared = inputSheetData?.[inputJsonKeys.isEnergyStarShared];
	const currentPage = inputSheetData?.[inputJsonKeys.energyStarPageNumber];

	// Updates the local and the redux state with the new object
	const updateEnergyStarData = (newObj) => {
		dispatch(updateInputSheetWithObj(newObj));
	};

	// The pages to display
	const energyStarPages = {
		[energyStarPageKeys.GET_ACCOUNT_NUMBER]: (
			<GetAccountNumber
				accountNumber={accountNumber}
				updateEnergyStarData={updateEnergyStarData}
				setShowSnackbar={setShowSnackbar}
				propId={propId}
			/>
		),
		[energyStarPageKeys.GET_PROPERTY_NUMBER]: (
			<GetPropertyNumber
				accountNumber={accountNumber}
				propertyNumber={propertyNumber}
				buildingName={selectedBuilding.prop_name}
				updateEnergyStarData={updateEnergyStarData}
				setShowSnackbar={setShowSnackbar}
				propId={propId}
			/>
		),
		[energyStarPageKeys.CONNECT_ACCOUNT]: (
			<EnergyStarConnectionSteps
				accountNumber={accountNumber}
				isConnected={isConnected}
				updateEnergyStarData={updateEnergyStarData}
				propId={propId}
			/>
		),
		[energyStarPageKeys.SHARE_PROPERTY]: (
			<EnergyStarSharePropertySteps
				propertyNumber={propertyNumber}
				isShared={isShared}
				updateEnergyStarData={updateEnergyStarData}
				propId={propId}
			/>
		),
		[energyStarPageKeys.SUCCESS]: (
			<SuccessPopup
				onClose={onClose}
				buildingName={selectedBuilding.prop_name}
			/>
		),
		[energyStarPageKeys.ALREADY_CONNECTED]: (
			<AlreadyConnected updateEnergyStarData={updateEnergyStarData} />
		),
		[energyStarPageKeys.ALREADY_SHARED]: (
			<AlreadyShared
				onClose={onClose}
				buildingName={selectedBuilding.prop_name}
			/>
		),
	};

	// Render the current page
	return <>{energyStarPages[currentPage]}</>;
}

export default EnergyStarOnboarding;
