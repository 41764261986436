import { useState } from 'react';
import { supabase } from '../../supabaseClient';
import { Dialog, DialogContent } from '@mui/material';
import '../dashboard/dataSharing/DataSharing.scss';
import fontStyles from '../../styles/Fonts.module.scss';
import messageStyles from '../uiTemplates/MessageTemplates.module.scss';
import styles from './auth.module.scss';
import PositionedSnackbar from '../snackbar/Snackbar';
import { VerificationCodeInputAndSubmit } from './MFAEnrollment';
import verifyingIllustration from '../../assets/illustrations/verifying-identity.svg';

function MFAVerification({ isOpen, handleMFACompletion }) {
	const [verifyCode, setVerifyCode] = useState('');
	const [isVerifying, setIsVerifying] = useState(false);
	// Keeps track of snackbar display for errors in owner settings udpate
	const [showSnackbar, setShowSnackbar] = useState({
		show: false,
		msg: '',
		severity: 'info',
	});

	const onSubmitClicked = () => {
		(async () => {
			setIsVerifying(true);
			try {
				// Get the list of MFA factors
				const factors = await supabase.auth.mfa.listFactors();
				if (factors.error) {
					throw factors.error;
				}

				const totpFactor = factors.data.totp[0];

				if (!totpFactor) {
					throw new Error('No TOTP factors found!');
				}

				const factorId = totpFactor.id;

				// Gets the challenge code for the MFA factor
				const challenge = await supabase.auth.mfa.challenge({ factorId });
				if (challenge.error) {
					throw challenge.error;
				}

				const challengeId = challenge.data.id;

				// Verify the code against the user input
				const verify = await supabase.auth.mfa.verify({
					factorId,
					challengeId,
					code: verifyCode,
				});

				if (verify.error) {
					throw verify.error;
				}

				// If the code is verified, complete the MFA process
				handleMFACompletion();
			} catch (error) {
				console.error('Error during MFA process:', error);
				setShowSnackbar({ msg: error.message, show: true, severity: 'error' });
			} finally {
				setIsVerifying(false);
			}
		})();
	};

	return (
		<>
			<PositionedSnackbar
				showmsg={showSnackbar.show}
				hidemsg={() =>
					setShowSnackbar((prevState) => {
						return { ...prevState, show: false };
					})
				}
				msg={showSnackbar.msg}
				severity={showSnackbar.severity}
			/>
			<Dialog
				open={isOpen}
				fullWidth
				style={{ padding: '20px' }}
				PaperProps={{ sx: { maxWidth: '700px', width: 'auto' } }}
				className="DataSharingDialog">
				<DialogContent>
					<div className={messageStyles.messageContainer}>
						<p className={styles.spacedOutTitles}>
							<span className={fontStyles.keyTitle}>Verify your identity</span>
							<span className={fontStyles.cardDescription}>
								Please enter the code from your authenticator app.
							</span>
						</p>
						<img src={verifyingIllustration} alt="Verifying Identity" />
						<VerificationCodeInputAndSubmit
							{...{ verifyCode, setVerifyCode, isVerifying, onSubmitClicked }}
						/>
					</div>
				</DialogContent>
			</Dialog>
		</>
	);
}

export default MFAVerification;
