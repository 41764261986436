import React, {
	useEffect,
	useCallback,
	useState,
	useMemo,
	useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IdleTimerProvider } from 'react-idle-timer';
import { Navigate, useLocation } from 'react-router-dom';
import {
	setAccessToken,
	clearAccessToken,
	clearRememberMe,
} from '../redux/auth/AuthSlice';
import { authApi } from '../api/';
import SessionInactivityPopup from '../components/onboarding/SessionInactivityPopup';
import { supabase } from '../supabaseClient';
import throttle from 'lodash/throttle';

const WithAuth = ({ children }) => {
	const dispatch = useDispatch();
	const accessToken = useSelector((state) => state.auth.accessToken);
	const rememberMe = useSelector((state) => state.auth.rememberMe);
	const [hasBeenIdle, setHasBeenIdle] = useState(false);
	const [showSessionPopup, setShowSessionPopup] = useState(false);
	const location = useLocation();
	const timerRef = useRef(null); // Reference to store the scheduled timer

	const pathsToExclude = [
		'/login',
		'/signup',
		'/forgot-password',
		'/reset-password',
		'/signupConfirm',
	];

	const refreshTokenIfNeeded = useCallback(async () => {
		if (showSessionPopup) {
			// No need to refresh token; user is unauthenticated or inactive
			return null; // Indicate that no further scheduling is needed
		}

		try {
			const { data, error } = await supabase.auth.getSession();
			if (error || !data.session) {
				setShowSessionPopup(true);
				return null;
			}
			const expirationTime = data.session.expires_at * 1000; // Convert to milliseconds
			const currentTime = new Date().getTime();
			const timeLeft = expirationTime - currentTime;

			if (timeLeft < 300 * 1000) {
				const { data: newSessionData, error: newSessionError } =
					await supabase.auth.refreshSession();
				if (newSessionError || !newSessionData.session) {
					setShowSessionPopup(true);
					return null;
				}
				dispatch(setAccessToken(newSessionData.session.access_token));
			}

			// Schedule the next check based on the remaining time or at least every 10 seconds
			return Math.max(timeLeft - 300 * 1000, 10 * 1000);
		} catch (err) {
			setShowSessionPopup(true);
			return null;
		}
	}, [dispatch, showSessionPopup]);

	const scheduleTokenCheck = useCallback(async () => {
		if (timerRef.current) {
			clearTimeout(timerRef.current); // Clear any previous timer
		}
		const nextCheckDelay = await refreshTokenIfNeeded();
		if (nextCheckDelay != null) {
			timerRef.current = setTimeout(scheduleTokenCheck, nextCheckDelay);
		} else {
			// No further scheduling needed
			timerRef.current = null;
		}
	}, [refreshTokenIfNeeded]);

	const handleOnIdle = () => {
		if (!pathsToExclude.includes(location.pathname)) {
			dispatch(clearAccessToken());
			dispatch(clearRememberMe());
			setHasBeenIdle(true);
		}
	};

	const handleLoginButtonClick = () => {
		setHasBeenIdle(false);
		setShowSessionPopup(false);
		authApi.signOut();
		dispatch(clearAccessToken());
	};

	const timeout = rememberMe ? 1000 * 60 * 60 * 24 : 1000 * 60 * 30; // 24 hours or 30 minutes

	const handleUserActivity = useMemo(() => {
		return throttle(() => {
			if (!showSessionPopup) {
				refreshTokenIfNeeded();
			}
		}, 10 * 1000);
	}, [refreshTokenIfNeeded, showSessionPopup]);

	useEffect(() => {
		if (!showSessionPopup) {
			window.addEventListener('mousemove', handleUserActivity);
			window.addEventListener('keydown', handleUserActivity);
			window.addEventListener('click', handleUserActivity);

			// Initiate the token check
			scheduleTokenCheck();
		}

		return () => {
			// Cleanup event listeners and timer on component unmount or when popup is shown
			window.removeEventListener('mousemove', handleUserActivity);
			window.removeEventListener('keydown', handleUserActivity);
			window.removeEventListener('click', handleUserActivity);
			if (timerRef.current) {
				clearTimeout(timerRef.current);
				timerRef.current = null;
			}
		};
	}, [scheduleTokenCheck, handleUserActivity, showSessionPopup]);

	// Clear timer when popup is shown
	useEffect(() => {
		if (showSessionPopup && timerRef.current) {
			clearTimeout(timerRef.current);
			timerRef.current = null;
		}
	}, [showSessionPopup]);

	let url = decodeURIComponent(window.location.href.split('/#')[1]);
	let urlParams = new URLSearchParams(url);
	let type = urlParams.get('type') || '';

	if (type === 'recovery' || type === 'signup' || type === 'invite') {
		window.location.href = url;
	} else {
		return accessToken ? (
			<>
				<SessionInactivityPopup
					isOpen={hasBeenIdle || showSessionPopup}
					onLoginClick={handleLoginButtonClick}
				/>
				<IdleTimerProvider
					timeout={timeout}
					onIdle={handleOnIdle}
					debounce={250}>
					{children}
				</IdleTimerProvider>
			</>
		) : (
			<Navigate to="/login" />
		);
	}
};

export default WithAuth;
