import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { styled } from '@mui/material/styles';

// Custom styling of Material UI's Tooltip component, setting the color to our Joulea green theme color
export const BootstrapTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: '#004225',
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#004225',
	},
}));

// Component for dropdowns which will hold text (the option in the dropdown) as well as a help icon, which, on hover,
// will display a tooltip with more information about that dropdown option. This component should be used as
// the options to be passed in to our InputDropdown component.
// Parameter text is the value of the dropdown option, and descriptionText is the description to show on help icon hover.
const DropdownWithDesc = ({ text, descriptionText }) => {
	return (
		<div
			style={{
				display: 'flex',
				width: '100%',
				justifyContent: 'space-between',
			}}>
			{/* Display the text first (starting at the far left) */}
			{text}
			{/* Wrap the help icon in the tooltip so that the Tooltip shows the description text on hover */}
			<BootstrapTooltip
				title={<div style={{ fontSize: '16px' }}>{descriptionText}</div>}
				arrow
				placement={'top'}
				TransitionComponent={Zoom}>
				<HelpOutlineIcon sx={{ color: '#004225' }} />
			</BootstrapTooltip>
		</div>
	);
};

export default DropdownWithDesc;
