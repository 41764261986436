import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isNavbarOpen: false,
};

const navbarToggleSlice = createSlice({
	name: 'navbarToggle',
	initialState,
	reducers: {
		navbarToggle: (state, { payload }) => {
			state.isNavbarOpen = payload;
		},
		resetToggle: (state) => {
			return {
				isNavbarOpen: false,
			};
		},
	},
});

export const { navbarToggle, resetToggle } = navbarToggleSlice.actions;

export default navbarToggleSlice.reducer;
