import { useState } from 'react';
import {
	checkAccountConnection,
	checkPropertySharing,
} from '../../../../../api/energyStarApi';
import { LoadingButton } from '@mui/lab';
import NumberInput from '../../../../inputSheet/numberInput/NumberInput';
import { inputJsonKeys, energyStarPageKeys } from './EnergyStarOnboarding';
import '../../DataSharing.scss';
import buttonStyles from '../../../../uiTemplates/Button.module.scss';

const unableToReachMessage =
	"Sorry, we're having trouble reaching to ENERGY STAR. Please try again or contact us for assistance.";

function GetEnergyStarInput({
	updateEnergyStarData,
	initialValue,
	inputName,
	inputLabel,
	inputJsonKey,
	successJsonKey,
	successPageKey,
	failPageKey,
	apiFunction,
	additionalParams = [],
	setShowSnackbar,
	propId,
}) {
	const [inputValue, setInputValue] = useState(initialValue);
	const [loading, setLoading] = useState(false);
	const [disabled, setDisabled] = useState(false);

	const handleValueSubmit = async () => {
		try {
			setLoading(true);
			const response = await apiFunction(
				propId,
				inputValue || 0,
				...additionalParams
			);

			// If the account is already connected, update the input sheet
			if (response.data === true) {
				updateEnergyStarData({
					[successJsonKey]: true,
					[inputJsonKeys.energyStarPageNumber]: successPageKey,
				});
			} else {
				updateEnergyStarData({
					[inputJsonKeys.energyStarPageNumber]: failPageKey,
				});
			}
			setDisabled(false);
		} catch (error) {
			setDisabled(true);
			setShowSnackbar(unableToReachMessage, 'error');
		} finally {
			setLoading(false);
		}

		updateEnergyStarData({
			[inputJsonKey]: inputValue,
		});
	};

	return (
		<>
			<div className="InputContainer">
				<label htmlFor={`${inputName}Number`}>
					<span className="labelTitle">{inputLabel.label}</span>
					{inputLabel.description}
				</label>
				<NumberInput
					propValue={inputValue}
					getChange={(value) => setInputValue(value)}
					name={`${inputName}Number`}
					placeholder={`Enter your ${inputName} number`}
					classes="textInput w-100"
				/>
			</div>
			<div className={buttonStyles.dataSharingButtons}>
				<LoadingButton
					variant="contained"
					onClick={handleValueSubmit}
					disabled={disabled}
					loading={loading}>
					Next
				</LoadingButton>
			</div>
		</>
	);
}

export function GetAccountNumber({
	accountNumber,
	updateEnergyStarData,
	setShowSnackbar,
	propId,
}) {
	return (
		<GetEnergyStarInput
			updateEnergyStarData={updateEnergyStarData}
			initialValue={accountNumber}
			inputName="account"
			inputLabel={{
				label: 'ENERGY STAR Account ID',
				description:
					'The account ID can be found from the Account Settings of ENERGY STAR Portfolio Manager',
			}}
			inputJsonKey={inputJsonKeys.energyStarAccountNumber}
			successJsonKey={inputJsonKeys.isEnergyStarConnected}
			successPageKey={energyStarPageKeys.ALREADY_CONNECTED}
			failPageKey={energyStarPageKeys.CONNECT_ACCOUNT}
			apiFunction={checkAccountConnection}
			propId={propId}
			setShowSnackbar={setShowSnackbar}
		/>
	);
}

export function GetPropertyNumber({
	accountNumber,
	propertyNumber,
	buildingName,
	updateEnergyStarData,
	setShowSnackbar,
	propId,
}) {
	return (
		<GetEnergyStarInput
			updateEnergyStarData={updateEnergyStarData}
			initialValue={propertyNumber}
			inputName="property"
			inputLabel={{
				label: `ENERGY STAR Property ID of ${buildingName}`,
				description:
					'The property ID can be found below the property name in the MyPortfolio Dashboard of ENERGY STAR Portfolio Manager',
			}}
			inputJsonKey={inputJsonKeys.energyStarPropertyNumber}
			successJsonKey={inputJsonKeys.isEnergyStarShared}
			successPageKey={energyStarPageKeys.ALREADY_SHARED}
			failPageKey={energyStarPageKeys.SHARE_PROPERTY}
			apiFunction={checkPropertySharing}
			propId={propId}
			additionalParams={[accountNumber || 0]}
			setShowSnackbar={setShowSnackbar}
		/>
	);
}
