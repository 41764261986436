import { GoTrueClient } from '@supabase/gotrue-js';
import { fetch } from '@supabase/node-fetch';
import type {
	AuthChangeEvent,
	Session,
} from '@supabase/gotrue-js/dist/main/lib/types';
import axiosInstance from './axiosInstance';
import { ApiResponse } from './types';

const auth = (() => {
	//Back end blows up if the body is empty for a JSON post request. This overloads fetch to add one if it does not exist
	const _fetch = (...args: any[]) => {
		let nArgs = args;
		if (nArgs.length > 1 && nArgs[1]?.method === 'POST' && !nArgs[1]?.body) {
			nArgs[1].body = '{}';
		}
		return fetch(...nArgs);
	};

	const authClient = new GoTrueClient({
		url: `${process.env.REACT_APP_backend_api_url}/auth/v1`,
		storageKey: `sb-${process.env.REACT_APP_backend_api_url}-auth-token`,
		autoRefreshToken: true,
		persistSession: true,
		detectSessionInUrl: true,
		flowType: 'implicit',
		fetch: _fetch,
	});

	return {
		inviteOwnerByEmail: async (email: string, group_id: string) => {
			const response = await axiosInstance.post('/v2/admin/invite-owner', {
				email,
				group_id,
			});
			return { ownerData: response.data.data, status: response.status };
		},

		inviteMemberByGroup: async (
			email: string,
			group_id: string,
			role: string,
			properties: []
		) => {
			const response = await axiosInstance.post(
				`/v2/g/${group_id}/users/invite-member`,
				{
					email,
					role,
					properties,
				}
			);
			return { data: response.data.data, status: response.status };
		},

		resendInviteByEmail: async (email: string) => {
			const response = await authClient.resend({
				email,
				type: 'signup',
				options: {
					emailRedirectTo: `${window.location.protocol}//${window.location.host}`,
				},
			});

			return { response: response };
		},

		signIn: async (email: string, password: string) => {
			try {
				const {
					data: { session, user },
					error,
				} = await authClient.signInWithPassword({
					email: email,
					password: password,
				});

				if (error) {
					throw error;
				}

				const accessToken = session?.access_token;

				return { user, accessToken };
			} catch (error) {
				throw error;
			}
		},
		signUp: async (email: string, password: string, metaData: {} = {}) => {
			// Removed try catch
			// TODO SET-1833: Un-used to be removed
			let options: any = {
				data: metaData,
			};
			const {
				data: { session, user },
				error,
			} = await authClient.signUp({
				email: email,
				password: password,
				options,
			});

			if (error) {
				throw error;
			}

			const accessToken = session?.access_token;

			return { user, accessToken };
		},
		confirmSignup: async (): Promise<ApiResponse<any>> => {
			// Removed try catch
			// TODO SET-1833: Un-used to be removed
			const response = await axiosInstance.put(
				`/v1/me/user-confirm-signup`,
				{}
			);

			return response.data;
		},

		// TODO SET-1832: This needs to be handled at all references
		signOut: async () => {
			try {
				const { error } = await authClient.signOut();

				if (error) {
					throw error;
				}

				return true;
			} catch (error) {
				throw error;
			}
		},
		resetPassword: async (email: string) => {
			// Removed try catch
			const { data, error } = await authClient.resetPasswordForEmail(email, {
				redirectTo: `${window.location.protocol}//${window.location.host}`,
			});

			if (error) {
				throw error;
			}

			return data;
		},
		updatePassword: async (newPassword: string) => {
			// Removed try catch
			const { data, error } = await authClient.updateUser({
				password: newPassword,
			});

			if (error) {
				throw new Error("Error updating user's password");
			}

			return data;
		},
		// TODO SET-1832: This needs to be handled at all references
		refreshUserToken: async () => {
			const { data, error } = await authClient.refreshSession();

			if (error) {
				throw error;
			}

			return data;
		},
		getSession: async () => {
			return authClient.getSession();
		},
		onAuthStateChange: (
			callback: (
				event: AuthChangeEvent,
				session: Session | null
			) => void | Promise<void>
		) => {
			return authClient.onAuthStateChange(callback);
		},
	};
})();
export default auth;
