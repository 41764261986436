import { useState, useEffect } from 'react';
import {
	FormControlLabel,
	Checkbox,
	CircularProgress,
	Typography,
} from '@mui/material';
import { acceptConnectionRequest } from '../../../../../api/energyStarApi';
import { energyStarPageKeys, inputJsonKeys } from './EnergyStarOnboarding';
import { ActionButtonGroup } from '../../CommonTemplates';
import { InstructionStep } from '../../CommonTemplates';
import '../../DataSharing.scss';

function EnergyStarConnectionSteps({
	accountNumber,
	isConnected,
	updateEnergyStarData,
	propId,
}) {
	const [hasConnectionCheckbox, setHasConnectionCheckbox] = useState(false);
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);

	const unableToAcceptMessage = `Sorry, we're having trouble accepting your connection request (Account ID: ${accountNumber}). Please try again or contact us for assistance.`;

	useEffect(() => {
		setHasConnectionCheckbox(isConnected);
	}, [isConnected]);

	const handleOnCheckboxChange = async () => {
		if (hasConnectionCheckbox) {
			setHasConnectionCheckbox(false);
			return;
		}
		try {
			setLoading(true);
			setErrorMessage('');
			const response = await acceptConnectionRequest(propId, {
				energy_star_account_id: accountNumber,
			});
			if (response.data === true) {
				setHasConnectionCheckbox(true);
				updateEnergyStarData({
					[inputJsonKeys.isEnergyStarConnected]: true,
					[inputJsonKeys.energyStarPageNumber]:
						energyStarPageKeys.GET_PROPERTY_NUMBER,
				});
			} else {
				setErrorMessage(unableToAcceptMessage);
			}
			setLoading(false);
		} catch (error) {
			setErrorMessage(unableToAcceptMessage);
			setLoading(false);
		}
	};

	return (
		<div className="InstructionsContainer">
			<p className="TopInstruction">
				Please follow the steps below to connect with Joulea on ENERGY STAR
				Portfolio Manager.
			</p>
			<div className="ConnectionSteps">
				<InstructionStep
					stepNumber={1}
					stepDescription={
						<>
							Log on to{' '}
							<a
								href="https://portfoliomanager.energystar.gov/pm/login.html"
								target="_blank"
								rel="noreferrer"
								style={{ textDecoration: 'underline', color: '#212121' }}>
								Energy Star Portfolio Manager.
							</a>
						</>
					}
				/>
				<InstructionStep
					stepNumber={2}
					stepDescription={
						<>
							Click <span className="bold">Contacts</span> in the top-right
							corner of the screen.
						</>
					}
				/>
				<InstructionStep
					stepNumber={3}
					stepDescription={
						<>
							Click <span className="bold">Add New Contacts/Connections</span>.
						</>
					}
				/>
				<InstructionStep
					stepNumber={4}
					stepDescription={
						<>
							Find Joulea by searching for the email{' '}
							<span className="bold">info@joulea.com</span>.
						</>
					}
				/>
				<InstructionStep
					stepNumber={5}
					stepDescription={
						<>
							When you find Joulea on the Search Results page, click{' '}
							<span className="bold">Connect</span>.
						</>
					}
				/>
				<InstructionStep
					stepNumber={6}
					stepDescription={
						<>
							Confirm by clicking on the button{' '}
							<span className="bold">Send connection request</span>.
						</>
					}
				/>
				<FormControlLabel
					control={
						loading ? (
							<CircularProgress
								size={20}
								className="checking-connection-spinner"
							/>
						) : (
							<Checkbox
								checked={hasConnectionCheckbox}
								onChange={() => !isConnected && handleOnCheckboxChange()}
							/>
						)
					}
					label="I have connected with info@joulea.com."
				/>
				{errorMessage && <Typography color="error">{errorMessage}</Typography>}
			</div>
			<ActionButtonGroup
				updateData={updateEnergyStarData}
				pageNumber={energyStarPageKeys.GET_ACCOUNT_NUMBER}
				pageJsonKey={inputJsonKeys.energyStarPageNumber}
			/>
		</div>
	);
}

export default EnergyStarConnectionSteps;
