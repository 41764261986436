import { requiredBuildingDocuments } from './const';
import { inputJsonKeys as energyDirectKeys } from './popups/energyDirect/EnergyDirectOnboarding';

// Calculate the progress of the energy direct module
export const calculateEnergyDirectProgress = (
	inputSheetData: Record<string, any>
) => {
	if (!inputSheetData || !energyDirectKeys) {
		return 0;
	}

	return inputSheetData[energyDirectKeys.energyDirectConsumptionData]?.length >
		0 || inputSheetData[energyDirectKeys.energyDirectUtilityBills]?.length > 0
		? 100
		: typeof inputSheetData[energyDirectKeys.energyDirectAccountExists] ===
		  'boolean'
		? 50
		: 0;
};

// Calculate the progress of the energy star module
export const calculateEnergyStarProgress = (
	inputSheetData: Record<string, any>
) => {
	if (!inputSheetData) {
		return 0;
	}

	return inputSheetData.isEnergyStarShared
		? 100
		: inputSheetData.energyStarPropertyNumber
		? 75
		: inputSheetData.isEnergyStarConnected
		? 50
		: inputSheetData.energyStarAccountNumber
		? 25
		: 0;
};

// Calculate the progress of the document module
export const calculateDocumentProgress = (
	inputSheetData: Record<string, any>
) => {
	if (!inputSheetData) {
		return 0;
	}

	// The list of required documents that have been uploaded
	const numberOfUploadedDocs = requiredBuildingDocuments.filter(
		(doc) => inputSheetData[doc]?.length > 0
	)?.length;
	const progress = Math.floor(
		(numberOfUploadedDocs / requiredBuildingDocuments.length) * 100
	);

	return progress;
};

// Calculate the progress of the drone logistics module
export const calculateDroneLogisticsProgress = (
	droneLogisticsData: Record<string, any>
) => {
	if (!droneLogisticsData) {
		return 0;
	}

	// Total number of fields filled in the drone logistics module
	let pagesFilled = 0;
	// Check if each field is filled and increment the pagesFilled accordingly
	const selectedDates = droneLogisticsData.selectedDates;

	if (droneLogisticsData.chargingLocations) pagesFilled++; // Compulsory field
	if (
		Array.isArray(selectedDates) &&
		selectedDates.length > 0 // Compulsory field
	)
		pagesFilled++;

	// Check preferredLocations
	if (droneLogisticsData.preferredLocations) {
		pagesFilled++;
	} else {
		// Check takeoffLocations if preferredLocations is empty
		const takeoffLocations = Object.values(droneLogisticsData.takeoffLocations);
		if (
			takeoffLocations.length > 0 &&
			takeoffLocations.some((location) => (location as any).selected === true)
		) {
			pagesFilled++;
		}
	}

	return pagesFilled;
};
