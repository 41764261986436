import { isEmpty } from 'lodash';

export function formatString(template, ...args) {
	return template.replace(/{}/g, (match) => args.shift());
}

/**
 * Safely retrieves a nested property from an object.
 * @param {object} object - The object to retrieve the property from.
 * @param {string} propertyPath - The path to the nested property using dot notation.
 * @param {any} defaultValue - The default value to return if the property is not found.
 * @returns {any} The value of the nested property or the default value if not found.
 */
export const getNestedProperty = (object, propertyPath, defaultValue = '') => {
	// Split the property path into individual property names
	const properties = propertyPath.split('.');
	let value = object;

	// Traverse through the nested properties
	for (const property of properties) {
		// Check if the current property exists in the current value
		if (!isEmpty(value) && value[property] !== undefined) {
			value = value[property]; // Update the value with the nested property
		} else {
			return defaultValue; // Return default value if property is not found
		}
	}

	return value; // Return the final nested property value
};
