import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as c2bemApi from '../../api/c2bemApi';

export const getC2bemAccuracy = createAsyncThunk(
	'c2bem/getC2bemAccuracy',
	async (payload, thunkAPI) => {
		try {
			// Fetch the accuracy data
			const { data } = await c2bemApi.fetchC2bemAccuracy(payload.property_id);
			if (data) {
				return { data };
			} else {
				return thunkAPI.rejectWithValue('No data found');
			}
		} catch (error) {
			return thunkAPI.rejectWithValue('Error fetching data');
		}
	}
);

export const getC2bemConsumptionData = createAsyncThunk(
	'c2bem/getC2bemConsumptionData',
	async (payload, thunkAPI) => {
		try {
			// Fetch the consumption data
			const { data } = await c2bemApi.fetchC2bemConsumptionData(
				payload.property_id
			);
			if (data) {
				return { data };
			} else {
				return thunkAPI.rejectWithValue('No data found');
			}
		} catch (error) {
			return thunkAPI.rejectWithValue('Error fetching data');
		}
	}
);

export const getC2bemDistributionData = createAsyncThunk(
	'c2bem/getC2bemDistributionData',
	async (payload, thunkAPI) => {
		try {
			// Fetch the distribution data
			const { data } = await c2bemApi.fetchC2bemDistributionData(
				payload.property_id
			);
			if (data) {
				return { data };
			} else {
				return thunkAPI.rejectWithValue('No data found');
			}
		} catch (error) {
			return thunkAPI.rejectWithValue('Error fetching data');
		}
	}
);

export const getC2bemBenchmarkData = createAsyncThunk(
	'c2bem/getC2bemBenchmarkData',
	async (_, thunkAPI) => {
		try {
			const { data: allBenchmarkData } =
				await c2bemApi.fetchC2bemBenchmarkData();

			if (allBenchmarkData) {
				return { allBenchmarkData };
			} else {
				return thunkAPI.rejectWithValue('No data found');
			}
		} catch (error) {
			return thunkAPI.rejectWithValue('Error fetching data');
		}
	}
);

// The initial state for consumption data
const initialConsumptionData = {
	modelAccuracy: null,
	updatedAt: null,
	dates: [],
	modelConsumption: [],
	historicalConsumption: [],
	heatingCoolingLoad: {
		heating: [],
		cooling: [],
	},
	potentialAnnualConsumption: 0,
};

// The initial state for the c2bem slice
export const initialC2bemState = {
	allConsumptionData: initialConsumptionData,
	isConsumptionLoading: false,
	isAccuracyLoading: false,
	consumptionErrorMessage: '',
	distributionData: {},
	isDistributionLoading: false,
	distributionErrorMessage: '',
};

const c2bemSlice = createSlice({
	name: 'c2bem',
	initialState: {
		allBenchmarkData: {},
		isBenchmarkLoading: false,
		benchmarkErrorMessage: '',
	},
	extraReducers: (builder) => {
		// Add the getC2bemAccuracy reducer here
		builder.addCase(getC2bemAccuracy.pending, (state, { meta }) => {
			const propertyId = meta.arg?.property_id;

			if (!propertyId) {
				return;
			}

			state[propertyId] = {
				...(propertyId in state ? state[propertyId] : initialC2bemState),
				isAccuracyLoading: true,
			};
		});
		builder.addCase(getC2bemAccuracy.fulfilled, (state, { payload, meta }) => {
			const propertyId = meta.arg?.property_id;

			if (!propertyId) {
				return;
			}

			state[propertyId] = {
				...(propertyId in state ? state[propertyId] : initialC2bemState),
				isAccuracyLoading: false,
				allConsumptionData: {
					...state[propertyId].allConsumptionData,
					modelAccuracy: payload.data,
				},
			};
		});

		// Add the getC2bemConsumptionData reducer here
		builder.addCase(getC2bemConsumptionData.pending, (state, { meta }) => {
			const propertyId = meta.arg?.property_id;

			if (!propertyId) {
				return;
			}

			state[propertyId] = {
				...(propertyId in state ? state[propertyId] : initialC2bemState),
				isConsumptionLoading: true,
			};
		});
		builder.addCase(
			getC2bemConsumptionData.fulfilled,
			(state, { payload, meta }) => {
				const propertyId = meta.arg?.property_id;

				if (!propertyId) {
					return;
				}

				state[propertyId] = {
					...(propertyId in state ? state[propertyId] : initialC2bemState),
					isConsumptionLoading: false,
					allConsumptionData: payload.data,
					consumptionErrorMessage: '',
				};
			}
		);
		builder.addCase(
			getC2bemConsumptionData.rejected,
			(state, { meta, error }) => {
				const propertyId = meta.arg?.property_id;

				if (!propertyId) {
					return;
				}

				state[propertyId] = {
					...(propertyId in state ? state[propertyId] : initialC2bemState),
					isConsumptionLoading: false,
					allConsumptionData: initialConsumptionData,
					consumptionErrorMessage: error.message,
				};
			}
		);

		// Add the getC2bemDistributionData reducer here
		builder.addCase(getC2bemDistributionData.pending, (state, { meta }) => {
			const propertyId = meta.arg?.property_id;

			if (!propertyId) {
				return;
			}

			state[propertyId] = {
				...(propertyId in state ? state[propertyId] : initialC2bemState),
				isDistributionLoading: true,
			};
		});
		builder.addCase(
			getC2bemDistributionData.fulfilled,
			(state, { payload, meta }) => {
				const propertyId = meta.arg?.property_id;

				if (!propertyId) {
					return;
				}

				state[propertyId] = {
					...(propertyId in state ? state[propertyId] : initialC2bemState),
					isDistributionLoading: false,
					distributionData: payload.data,
					distributionErrorMessage: '',
				};
			}
		);
		builder.addCase(
			getC2bemDistributionData.rejected,
			(state, { meta, error }) => {
				const propertyId = meta.arg?.property_id;

				if (!propertyId) {
					return;
				}

				state[propertyId] = {
					...(propertyId in state ? state[propertyId] : initialC2bemState),
					isDistributionLoading: false,
					distributionData: {},
					distributionErrorMessage: error.message,
				};
			}
		);

		// Add the getC2bemBenchmarkData reducer here
		builder.addCase(getC2bemBenchmarkData.pending, (state) => {
			state.isBenchmarkLoading = true;
		});
		builder.addCase(getC2bemBenchmarkData.fulfilled, (state, { payload }) => {
			state.isBenchmarkLoading = false;
			state.allBenchmarkData = payload.allBenchmarkData;
			state.benchmarkErrorMessage = '';
		});
		builder.addCase(getC2bemBenchmarkData.rejected, (state, { error }) => {
			state.isBenchmarkLoading = false;
			state.allBenchmarkData = {};
			state.benchmarkErrorMessage = error.message;
		});
	},
});

export default c2bemSlice.reducer;
