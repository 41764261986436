import React, { useEffect } from 'react';
import {
	BrowserRouter as Router,
	Route,
	Routes,
	useNavigate,
	useLocation,
} from 'react-router-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { persistor, store } from './Store';
import { PersistGate } from 'redux-persist/integration/react';
import './App.scss';
import CustomTheme from './muiCustomTheme';
import { ThemeProvider } from '@mui/material/styles';
import { getUserDetails } from './redux/user/UserSlice';
import { StyledEngineProvider } from '@mui/material/styles';
import loadable from '@loadable/component';
import { setAccessToken } from './redux/auth/AuthSlice';
import { resetSelectedBuilding } from './redux/selectedBuilding/SelectedBuildingSlice';
import { AstronautLoader } from './pages/Login';
import './styles/Fonts.module.scss';
import { RestrictedRoute } from './withAuth/RestrictedRoute';
import { authApi } from './api/';
import ReactGA from 'react-ga4';
import WithAuth from './withAuth/WithAuth';

ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);

const Opx = loadable(() => import('./pages/Opx'), {
	fallback: AstronautLoader,
});
const Login = loadable(() => import('./pages/Login'), {
	fallback: AstronautLoader,
});

const Signup = loadable(() => import('./pages/Signup'), {
	fallback: AstronautLoader,
});

const SessionConfirmation = loadable(
	() => import('./pages/SessionConfirmation'),
	{
		fallback: AstronautLoader,
	}
);

const ForgotPassword = loadable(() => import('./pages/ForgotPassword'), {
	fallback: AstronautLoader,
});

const ResetPassword = loadable(() => import('./pages/ResetPassword'), {
	fallback: AstronautLoader,
});

const SignupConfirm = loadable(() => import('./pages/SignupConfirmation'), {
	fallback: AstronautLoader,
});

const C2BEM = loadable(() => import('./pages/C2bem'), {
	fallback: AstronautLoader,
});

const Upgrades = loadable(() => import('./pages/Upgrades'), {
	fallback: AstronautLoader,
});

const RocketLeague = loadable(() => import('./pages/RocketLeague'), {
	fallback: AstronautLoader,
});

const EnvelopeExplorer = loadable(() => import('./pages/EnvelopeExplorer'), {
	fallback: AstronautLoader,
});

const PortfolioManager = loadable(() => import('./pages/PortfolioManager'), {
	fallback: AstronautLoader,
});

const BuildingHome = loadable(() => import('./pages/BuildingHome'), {
	fallback: AstronautLoader,
});

const Contact = loadable(() => import('./pages/Contact'), {
	fallback: AstronautLoader,
});

const Settings = loadable(() => import('./pages/Settings'), {
	fallback: AstronautLoader,
});

const InputSheet = loadable(() => import('./pages/InputSheet'), {
	fallback: AstronautLoader,
});

const Admin = loadable(() => import('./pages/Admin'), {
	fallback: AstronautLoader,
});

const Page404 = loadable(() => import('./pages/404'), {
	fallback: AstronautLoader,
});

const Layout = loadable(() => import('./layout/Layout'), {
	fallback: AstronautLoader,
});

const RockerLeagueLayout = loadable(
	() => import('./layout/RockerLeagueLayout'),
	{
		fallback: AstronautLoader,
	}
);

const IntercomChatbot = loadable(
	() => import('./components/Chatbot/Intercom'),
	{
		fallback: AstronautLoader,
	}
);

const AdminLayout = loadable(() => import('./layout/AdminLayout'), {
	fallback: AstronautLoader,
});

const Groups = loadable(() => import('./components/admins/groups'), {
	fallback: AstronautLoader,
});

const Users = loadable(() => import('./components/admins/users'), {
	fallback: AstronautLoader,
});

const Property = loadable(() => import('./components/admins/property'), {
	fallback: AstronautLoader,
});

const AppWrapper = () => {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<StyledEngineProvider injectFirst>
					<Router>
						<App />
					</Router>
				</StyledEngineProvider>
			</PersistGate>
		</Provider>
	);
};

function App() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const user = useSelector((state) => state.user);
	const buildings = useSelector((state) => state.buildings);
	const location = useLocation();
	React.useEffect(() => {
		const TIMESTAMP = process.env.REACT_APP_CACHE_TIMESTAMP;
		const CLEAR_STORAGE = process.env.REACT_APP_CLEAR_STORAGE;
		if (localStorage.APP_TIMESTAMP !== TIMESTAMP) {
			if (CLEAR_STORAGE) {
				localStorage.clear();
			}
		}
		localStorage.setItem('APP_TIMESTAMP', TIMESTAMP);

		// Define an event handler function
		const handleAuthChange = (event, session) => {
			const access_token = session?.access_token;

			switch (event) {
				case 'SIGNED_IN':
				case 'USER_UPDATED':
				case 'TOKEN_REFRESHED':
					// Handle user  sign-in, user session update, or token refresh
					if (access_token) {
						dispatch(setAccessToken(access_token));
					}
					break;

				case 'SIGNED_OUT':
					// Handle user sign-out
					dispatch(setAccessToken('')); // Clear the access token in your store
					dispatch(resetSelectedBuilding());

					// Redirect to the login page
					if (location.pathname !== '/login') {
						navigate('/login');
					}
					break;

				default:
					console.warn('Unknown event:', event);
					break;
			}
		};

		// Add the event handler
		const { data: subscription } = authApi.onAuthStateChange(handleAuthChange);

		return () => {
			// Cleanup the listener when the component unmounts
			if (subscription) {
				subscription.unsubscribe(); // Unsubscribe from the event
			}
		};
	}, [dispatch]);

	React.useEffect(() => {
		if (location.pathname === '/signup') {
			return;
		}
		authApi.getSession().then(({ data }) => {
			if (!data) {
				return;
			}
			const { session } = data;
			if (session?.user?.confirmed_at) {
				const email = session.user.email;
				dispatch(getUserDetails({ user_id: email }));
			}
		});
	}, []);

	React.useEffect(() => {
		if (location.pathname === '/signup') {
			return;
		}
		const { error, userDetails } = user;
		if (userDetails.user_id) {
			// dispatch(getBuildings(userDetails));
		} else if (error && error.code === 'USER_NOT_FOUND') {
			navigate('/404');
		}
	}, [dispatch, navigate, location, user]);

	React.useEffect(() => {
		if (
			buildings.error &&
			buildings.error.message === 'FetchError: Failed to fetch'
		) {
			navigate('/404');
		}
	}, [buildings, navigate]);

	// Track pageviews
	useEffect(() => {
		ReactGA.send({
			hitType: 'pageview',
			page: location.pathname,
			title: location.pathname,
		});
	}, [location]);

	return (
		<div className="App">
			<div className="grain-texture">
				<svg>
					<filter id="noiseFilter">
						<feTurbulence
							type="fractalNoise"
							baseFrequency="0.4"
							stitchTiles="stitch"
						/>
						<feColorMatrix
							in="colorNoise"
							type="matrix"
							values="1.000  0.000  0.000  0.005  0.000 
							0.000  3.000  0.000  0.025  0.000 
							0.000  0.000  1.000  0.015  0.000 
							0.000  0.000  0.000  0.025  0.000"
						/>
						<feComposite operator="in" in2="SourceGraphic" result="monoNoise" />
						<feBlend in="SourceGraphic" in2="monoNoise" mode="screen" />
					</filter>
				</svg>
			</div>

			<header className="App-header">
				<IntercomChatbot />
				<Routes>
					<Route
						exact
						path="/admin"
						element={
							<WithAuth>
								<ThemeProvider theme={CustomTheme}>
									<AdminLayout child={Admin} />
								</ThemeProvider>
							</WithAuth>
						}
					/>
					<Route
						exact
						path="/admin/groups"
						element={
							<WithAuth>
								<ThemeProvider theme={CustomTheme}>
									<AdminLayout child={Groups} />
								</ThemeProvider>
							</WithAuth>
						}
					/>
					<Route
						exact
						path="/admin/users"
						element={
							<WithAuth>
								<ThemeProvider theme={CustomTheme}>
									<AdminLayout child={Users} />
								</ThemeProvider>
							</WithAuth>
						}
					/>
					<Route
						exact
						path="/admin/property"
						element={
							<WithAuth>
								<ThemeProvider theme={CustomTheme}>
									<AdminLayout child={Property} />
								</ThemeProvider>
							</WithAuth>
						}
					/>
					<Route
						exact
						path="/login"
						element={
							<ThemeProvider theme={CustomTheme}>
								<Login />
							</ThemeProvider>
						}
					/>
					<Route
						exact
						path="/404"
						element={
							<ThemeProvider theme={CustomTheme}>
								<Page404 />
							</ThemeProvider>
						}
					/>
					<Route
						exact
						path="/signup"
						element={
							<ThemeProvider theme={CustomTheme}>
								<Signup />
							</ThemeProvider>
						}
					/>
					<Route
						exact
						path="/session-confirmation"
						element={
							<ThemeProvider theme={CustomTheme}>
								<SessionConfirmation />
							</ThemeProvider>
						}
					/>
					<Route
						exact
						path="/forgotPassword"
						element={
							<ThemeProvider theme={CustomTheme}>
								<ForgotPassword />
							</ThemeProvider>
						}
					/>
					<Route
						exact
						path="/resetPassword"
						element={
							<ThemeProvider theme={CustomTheme}>
								<ResetPassword />
							</ThemeProvider>
						}
					/>
					<Route
						exact
						path="/signupConfirm"
						element={
							<ThemeProvider theme={CustomTheme}>
								<SignupConfirm />
							</ThemeProvider>
						}
					/>
					<Route
						exact
						path="/"
						element={
							<WithAuth>
								<ThemeProvider theme={CustomTheme}>
									<Layout child={BuildingHome} />
								</ThemeProvider>
							</WithAuth>
						}
					/>
					<Route
						exact
						path="/building-home"
						element={
							<WithAuth>
								<ThemeProvider theme={CustomTheme}>
									<Layout child={BuildingHome} />
								</ThemeProvider>
							</WithAuth>
						}
					/>
					<Route
						exact
						path="/opx"
						element={
							<WithAuth>
								<ThemeProvider theme={CustomTheme}>
									<Layout child={Opx} />
								</ThemeProvider>
							</WithAuth>
						}
					/>
					<Route
						exact
						path="/buildingInfoForm/:buildingId"
						element={
							<WithAuth>
								<ThemeProvider theme={CustomTheme}>
									<InputSheet />
								</ThemeProvider>
							</WithAuth>
						}
					/>
					<Route
						exact
						path="/buildingInfoForm"
						element={
							<WithAuth>
								<ThemeProvider theme={CustomTheme}>
									<Layout child={InputSheet} />
								</ThemeProvider>
							</WithAuth>
						}
					/>
					<Route
						exact
						path="/c2bem"
						element={
							<WithAuth>
								<ThemeProvider theme={CustomTheme}>
									<Layout child={C2BEM} />
								</ThemeProvider>
							</WithAuth>
						}
					/>
					<Route
						exact
						path="/capx"
						element={
							<WithAuth>
								<ThemeProvider theme={CustomTheme}>
									<Layout child={Upgrades} />
								</ThemeProvider>
							</WithAuth>
						}
					/>
					<Route
						exact
						path="/envelope-explorer"
						element={
							<WithAuth>
								<ThemeProvider theme={CustomTheme}>
									<Layout child={EnvelopeExplorer} />
								</ThemeProvider>
							</WithAuth>
						}
					/>
					<Route
						exact
						path="/portfolio-manager"
						element={
							<WithAuth>
								<RestrictedRoute>
									<ThemeProvider theme={CustomTheme}>
										<Layout child={PortfolioManager} />
									</ThemeProvider>
								</RestrictedRoute>
							</WithAuth>
						}
					/>
					<Route
						exact
						path="/contact-us"
						element={
							<WithAuth>
								<ThemeProvider theme={CustomTheme}>
									<Layout child={Contact} />
								</ThemeProvider>
							</WithAuth>
						}
					/>
					<Route
						exact
						path="/settings"
						element={
							<WithAuth>
								<ThemeProvider theme={CustomTheme}>
									<Layout child={Settings} />
								</ThemeProvider>
							</WithAuth>
						}
					/>
					<Route
						exact
						path="/rocketleague"
						element={
							<WithAuth>
								<ThemeProvider theme={CustomTheme}>
									<RockerLeagueLayout child={RocketLeague} />
								</ThemeProvider>
							</WithAuth>
						}
					/>
					<Route
						exact
						path="/settings/:tab"
						element={
							<WithAuth>
								<ThemeProvider theme={CustomTheme}>
									<Layout child={Settings} />
								</ThemeProvider>
							</WithAuth>
						}
					/>
				</Routes>
			</header>
		</div>
	);
}

export default AppWrapper;
