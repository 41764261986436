import { useDispatch, useSelector } from 'react-redux';
import { initialInputSheet } from '../../../../../assets/initial_input_sheet';
import { updateInputSheetWithObj } from '../../../../../redux/selectedBuilding/SelectedBuildingSlice';
import '../../DataSharing.scss';
import { CongratsTemplate } from '../../CommonTemplates';
import { AccountExists } from './AccountExists';
import {
	UploadUtilityBill,
	UploadConsumptionData,
} from './UploadEnergyDirectDocuments';

export const energyDirectPageKeys = {
	ACCOUNT_EXISTS: 0,
	UPLOAD_UTILITY_BILL: 1,
	UTILITY_BILL_SUCCESS: 2,
	UPLOAD_CONSUMPTION_DATA: 3,
	CONSUMPTION_DATA_SUCCESS: 4,
};

export const inputJsonKeys = {
	energyDirectAccountExists: 'energyDirectAccountExists',
	energyDirectUtilityBills: 'energyDirectUtilityBills',
	energyDirectConsumptionData: 'energyDirectConsumptionData',
	energyDirectPageNumber: 'energyDirectPageNumber',
};

function EnergyDirectOnboarding({ onClose, setShowSnackbar }) {
	const selectedBuilding = useSelector((state) => state.selectedBuilding);
	const dispatch = useDispatch();
	const inputData = selectedBuilding?.input_sheet ?? initialInputSheet;

	const currentPage = inputData?.[inputJsonKeys.energyDirectPageNumber];

	const updateEnergyDirectData = (newObj) => {
		dispatch(updateInputSheetWithObj(newObj));
	};

	const energyDirectPages = {
		[energyDirectPageKeys.ACCOUNT_EXISTS]: (
			<AccountExists
				inputData={inputData}
				updateEnergyDirectData={updateEnergyDirectData}
			/>
		),
		[energyDirectPageKeys.UPLOAD_UTILITY_BILL]: (
			<UploadUtilityBill
				inputData={inputData}
				updateEnergyDirectData={updateEnergyDirectData}
				setShowSnackbar={setShowSnackbar}
				buildingName={selectedBuilding.prop_name}
				propertyId={selectedBuilding.prop_id}
			/>
		),
		[energyDirectPageKeys.UTILITY_BILL_SUCCESS]: (
			<UtilityBillSuccess onClose={onClose} />
		),
		[energyDirectPageKeys.UPLOAD_CONSUMPTION_DATA]: (
			<UploadConsumptionData
				inputData={inputData}
				updateEnergyDirectData={updateEnergyDirectData}
				setShowSnackbar={setShowSnackbar}
				buildingName={selectedBuilding.prop_name}
				propertyId={selectedBuilding.prop_id}
			/>
		),
		[energyDirectPageKeys.CONSUMPTION_DATA_SUCCESS]: (
			<ConsumptionDataSuccess onClose={onClose} />
		),
	};

	return <p>{energyDirectPages[currentPage]}</p>;
}

export default EnergyDirectOnboarding;

function UtilityBillSuccess({ onClose }) {
	return (
		<CongratsTemplate
			title="Heads up!"
			message="Primary users of the Energy Direct accounts will soon get emails asking for secondary user access. Please accept these requests."
			onClick={onClose}
		/>
	);
}

function ConsumptionDataSuccess({ onClose }) {
	return (
		<CongratsTemplate
			title="Thank you!"
			message="Your consumption data has been received. We'll assess it shortly and will be in touch if necessary."
			onClick={onClose}
		/>
	);
}
