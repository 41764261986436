import { supabase } from '../supabaseClient.js';

export async function signInWithPassword(email, password) {
	try {
		// Supabase auth
		const { data, error } = await supabase.auth.signInWithPassword({
			email: email,
			password: password,
		});
		if (error) {
			throw error;
		}
		const user = data?.user;
		const accessToken = data?.session?.access_token;

		return {
			user: user,
			accessToken: accessToken,
		};
	} catch (error) {
		throw error;
	}
}

export async function getAuthenticationLevel() {
	try {
		const { data, error } =
			await supabase.auth.mfa.getAuthenticatorAssuranceLevel();
		if (error) {
			throw error;
		}

		const currentLevel = data?.currentLevel;
		const nextLevel = data?.nextLevel;

		return {
			currentLevel: currentLevel,
			nextLevel: nextLevel,
		};
	} catch (error) {
		throw error;
	}
}
