import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import LoadingButton from '@mui/lab/LoadingButton';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useDispatch } from 'react-redux';
import { getUserDetails } from '../redux/user/UserSlice';
import { setAccessToken, setRememberMe } from '../redux/auth/AuthSlice';
import { getBuildings } from '../redux/buildings/BuildingsSlice';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { authApi } from '../api/';
import cardStyles from '../styles/Cards.module.scss';
import loaderAstronaut from '../assets/loader-image.png';
import fontStyles from '../styles/Fonts.module.scss';
import whiteTextLogo from '../assets/joulea-icons/logo/textLogoWhite.png';
import CircularProgress from '@mui/material/CircularProgress';
import { supabase } from '../supabaseClient';
import {
	signInWithPassword,
	getAuthenticationLevel,
} from '../utils/authentication';
import MFAVerification from '../components/auth/MFAVerification';

export const AstronautLoader = (
	<div className="astronautLoaderContainer">
		<img
			src={loaderAstronaut}
			alt={'loader'}
			className="astronautLoaderImage"
		/>
		<svg height={40} width={60}>
			<ellipse cx={30} cy={20} rx={30} ry={10} />
		</svg>
		<p className={fontStyles.astronautLoaderText}>
			Launching your dashboard...
		</p>
	</div>
);

function Copyright(props) {
	return (
		<Typography
			variant="body2"
			color="text.secondary"
			align="center"
			{...props}>
			{'Copyright © Joulea '} {new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

export default function SignIn() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [loading, setLoading] = React.useState(false);
	const [dashboardLoading, setDashboardLoading] = React.useState(false);
	const [passlength, setPasslength] = React.useState(false);
	const [emailwrong, setEmailwrong] = React.useState(false);
	const [APIMessage, setAPIMessage] = React.useState('');
	const [passVisible, setPassVisible] = React.useState(false);
	const [checked, setChecked] = React.useState(false);
	const [showMFA, setShowMFA] = React.useState(false);
	const [userDetails, setUserDetails] = React.useState({
		user: {},
		accessToken: '',
	});

	React.useEffect(() => {
		const data = authApi.getSession().then(({ data }) => {
			if (data) {
				authApi.signOut();
				dispatch(setAccessToken(''));
			}
		});
	}, [dispatch]);

	const handleChange = (event) => {
		setChecked(!checked);
	};

	const handleSubmit = async (event) => {
		setPasslength(false);
		setEmailwrong(false);
		setAPIMessage('');
		event.preventDefault();
		let formData = new FormData(event.currentTarget);
		formData = {
			email: formData.get('email'),
			password: formData.get('password'),
		};

		if (formData.password.length < 6) {
			setPasslength(true);
		} else if (
			!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)
		) {
			setEmailwrong(true);
		} else {
			try {
				setLoading(true);
				// auth user
				const { user, accessToken } = await signInWithPassword(
					formData.email,
					formData.password
				);
				setUserDetails({ user, accessToken });

				if (user) {
					const { currentLevel, nextLevel } = await getAuthenticationLevel();
					// Check if user has MFA enrolled but not verified
					if (nextLevel === 'aal2' && nextLevel !== currentLevel) {
						setShowMFA(true);
					} else {
						handleMFACompletion();
					}
				}
			} catch (error) {
				setAPIMessage(error.message);
				setLoading(false);
			}
		}
	};

	const handleMFACompletion = () => {
		dispatch(setAccessToken(userDetails.accessToken));
		if (checked) {
			dispatch(setRememberMe(true));
		}
		dispatch(getUserDetails({ user_id: userDetails.user.id }))
			.unwrap()
			.then(async (res) => {
				setDashboardLoading(true);
				dispatch(getBuildings(res)).then(() => {
					navigate('/');
					setDashboardLoading(false);
				});
			})
			.catch((error) => {
				setAPIMessage(error.message);
				setLoading(false);
			});
	};

	if (showMFA) {
		return (
			<MFAVerification
				isOpen={showMFA}
				handleMFACompletion={handleMFACompletion}
			/>
		);
	}

	return (
		<Container component="main" maxWidth="xs">
			{dashboardLoading ? (
				AstronautLoader
			) : (
				<div className="loginContainer">
					<div className="loginLogoContainer">
						<img src={whiteTextLogo} alt={'logo'} />
					</div>
					<div className={cardStyles.cardContainer}>
						<CssBaseline />
						<Box
							sx={{
								marginTop: 8,
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
							}}>
							<Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
								<LockOutlinedIcon />
							</Avatar>
							<Typography component="h1" variant="h5">
								Sign in
							</Typography>
							<Box
								component="form"
								onSubmit={handleSubmit}
								noValidate
								sx={{ mt: 1 }}>
								<TextField
									margin="normal"
									required
									fullWidth
									id="email"
									label="Email Address"
									name="email"
									autoComplete="email"
									autoFocus
								/>
								<TextField
									margin="normal"
									required
									fullWidth
									name="password"
									label="Password"
									type={passVisible ? 'text' : 'password'}
									id="password"
									autoComplete="current-password"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													onClick={() => setPassVisible(!passVisible)}
													edge="end">
													{passVisible ? (
														<VisibilityIcon />
													) : (
														<VisibilityOffIcon />
													)}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
								<FormControlLabel
									control={
										<Checkbox checked={checked} onChange={handleChange} />
									}
									label="Remember me"
								/>
								<LoadingButton
									type="submit"
									fullWidth
									variant="contained"
									loading={loading}
									sx={{ mt: 3, mb: 2 }}>
									Sign In
								</LoadingButton>

								{passlength === true && (
									<Typography style={{ color: 'red' }} fullWidth align="center">
										Password shouldn't less than 6 characters
									</Typography>
								)}
								{emailwrong === true && (
									<Typography style={{ color: 'red' }} fullWidth align="center">
										Please enter a valid email address
									</Typography>
								)}
								{APIMessage && (
									<Typography style={{ color: 'red' }} fullWidth align="center">
										{APIMessage}
									</Typography>
								)}
								<br></br>
								<Grid container>
									<Grid item xs>
										<Link href="forgotPassword" variant="body2">
											Forgot password?
										</Link>
									</Grid>
								</Grid>
							</Box>
						</Box>
						<Copyright sx={{ mt: 8, mb: 4 }} />
					</div>
				</div>
			)}
		</Container>
	);
}
