/**
 * Maps user roles to their respective numerical value
 */
export const userRoleMap: { [key: string]: number } = {
	// Owner (highest) -> User (lowest)
	Owner: 0,
	Regional_Manager: 1,
	City_Manager: 2,
	// Default
	User: 3,
	// Usually Joulea
	Admin: 4,
};

/**
 * Maps role numbers to their respective description
 */
export const roleUserMap: { [key: number]: string } = {
	// Owner (highest) -> User (lowest)
	0: 'Owner',
	1: 'Regional Manager',
	2: 'City Manager',
	// Default
	3: 'User',
	// Usually Joulea
	4: 'Admin',
};

export const findRoleByValue = (value: string): number | undefined => {
	const role = Object.entries(roleUserMap).find(
		([, roleName]) => roleName === value
	);

	if (role) {
		return userRoleMap[role[0]];
	}

	return undefined; // Role not found
};

export const nonAdminRole: { [key: string]: number } = {
	// Owner (highest) -> User (lowest)
	Owner: 0,
	Regional_Manager: 1,
	City_Manager: 2,
	// Default
	User: 3,
};
