import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	Button,
	Checkbox,
	CircularProgress,
	FormControl,
	FormControlLabel,
	FormGroup,
	Stack,
} from '@mui/material';
import fontStyles from '../../../../../styles/Fonts.module.scss';
import { notaLabel } from './DroneLogistics';
import { droneLogisticsApi, envelopeExplorerApi } from '../../../../../api';
import NotFound from '../../../../../assets/illustrations/not-found.svg';

export function TakeoffLocationSelectionPopup({
	onSubmit,
	onBack,
	takeoffLocations,
	preferredLocations,
	setDroneLogisticsData,
}) {
	const [overviewImage, setOverviewImage] = useState(null);
	const [overviewImageLoading, setOverviewImageLoading] = useState(false);
	const [overviewImageError, setOverviewImageError] = useState(null);
	const [noneOfTheAbove, setNoneOfTheAbove] = useState(
		preferredLocations !== '' ? true : false
	);
	const propertyId = useSelector((state) => state.selectedBuilding)?.prop_id;

	const areTakeoffLocationsSelected =
		takeoffLocations &&
		Object.values(takeoffLocations)?.some((value) => value.selected);

	useEffect(() => {
		// Fetch the overview image from the database
		const fetchOverviewImage = async () => {
			try {
				setOverviewImageLoading(true);
				const data = await envelopeExplorerApi.getOverviewImage(propertyId);
				setOverviewImage(data.data);
			} catch (error) {
				setOverviewImageError(error);
			} finally {
				setOverviewImageLoading(false);
			}
		};
		fetchOverviewImage();
	}, [propertyId]);

	const handleLocationSubmit = (event, locationKey) => {
		// If a location is selected, uncheck the none of the above checkbox
		setNoneOfTheAbove(false);
		setDroneLogisticsData((prevData) => {
			return {
				...prevData,
				preferredLocations: '',
				takeoffLocations: prevData.takeoffLocations?.map((location, index) => ({
					...location,
					selected:
						Number(index) === Number(locationKey)
							? !location.selected
							: location.selected,
				})),
			};
		});
	};

	const handleNotaCheck = () => {
		// If none of the above is selected, reset the takeoff locations
		setDroneLogisticsData((prevData) => {
			return {
				...prevData,
				...(noneOfTheAbove && { preferredLocations: '' }),
				takeoffLocations: prevData?.takeoffLocations?.map((location) => ({
					...location,
					selected: false,
				})),
			};
		});

		setNoneOfTheAbove(!noneOfTheAbove);
	};

	const handlePreferredLocationsChange = (e) => {
		setDroneLogisticsData((prevData) => ({
			...prevData,
			preferredLocations: e.target.value,
		}));
	};

	const handleSubmit = () => {
		onSubmit();
	};

	return (
		<>
			<Stack direction="row" className="takeoffLocationSelectionPopup">
				<TakeoffLocationSelectionSection
					takeoffLocations={takeoffLocations}
					onLocationSubmit={handleLocationSubmit}
					noneOfTheAbove={noneOfTheAbove}
					onNotaCheck={handleNotaCheck}
					onPreferredLocationsChange={handlePreferredLocationsChange}
					preferredLocations={preferredLocations}
				/>
				<div className="takeoffLocationImage">
					{overviewImageLoading ? (
						<CircularProgress />
					) : overviewImageError ? (
						<img
							src={overviewImage}
							alt="building takeoff locations not found"
						/>
					) : (
						<img
							onError={(e) => (e.target.src = NotFound)}
							src={overviewImage}
							alt="building takeoff locations"
						/>
					)}
				</div>
			</Stack>
			<div className="DataSharingButtons">
				<Button onClick={onBack} className="backButton">
					Back
				</Button>
				<Button
					onClick={handleSubmit}
					variant="contained"
					disabled={!areTakeoffLocationsSelected && !preferredLocations}>
					NEXT
				</Button>
			</div>
		</>
	);
}

function TakeoffLocationSelectionSection({
	takeoffLocations,
	onLocationSubmit,
	noneOfTheAbove,
	onNotaCheck,
	onPreferredLocationsChange,
	preferredLocations,
}) {
	return (
		<div className="takeoffLocation">
			<p className={fontStyles.cardSmallTitle}>Takeoff Locations</p>
			{Object.keys(takeoffLocations).length !== 0 && (
				<>
					<p>
						Select the locations where we can take-off/land the drones
						(preferably, at least 2).{' '}
					</p>

					<FormControl component="fieldset" variant="standard">
						<FormGroup>
							{Object.keys(takeoffLocations).map((locationKey) => (
								<FormControlLabel
									key={locationKey}
									control={
										<Checkbox
											checked={takeoffLocations[locationKey].selected}
											onChange={(e) => onLocationSubmit(e, locationKey)}
											name={locationKey}
										/>
									}
									label={takeoffLocations[locationKey].location_name}
								/>
							))}
							<FormControlLabel
								control={
									<Checkbox
										checked={noneOfTheAbove}
										onChange={onNotaCheck}
										name={notaLabel}
									/>
								}
								label={notaLabel}
							/>
						</FormGroup>
					</FormControl>
				</>
			)}
			{(noneOfTheAbove || Object.keys(takeoffLocations).length === 0) && (
				<>
					<p className={fontStyles.cardSmallTitle}>
						What are your preferred take-off locations?
					</p>
					<textarea
						id="preferred_locations"
						rows="5"
						placeholder="Enter your preferred locations here."
						onChange={onPreferredLocationsChange}
						value={preferredLocations}></textarea>
				</>
			)}
		</div>
	);
}
