import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	//<React.StrictMode>
	<App />
	//</React.StrictMode>
);

// Register the service worker
if ('serviceWorker' in navigator) {
	window.addEventListener('load', () => {
		navigator.serviceWorker.register('/serviceWorker.js').then(
			(registration) => {
				// Service Worker Registration Successful
			},
			(error) => {
				// Service Worker Registration Failed
				console.error('Error while registering service worker:', error);
			}
		);
	});
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
